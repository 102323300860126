import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { APP_CONST } from "../../../../config/const";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { asyncSocketEmit, socketFunc } from "../../../../utils/socketUtil";
import { Toaster } from "../../../../utils/toaster";
import useImportScript from "../../../../hooks/useImportScript";
import { useSelector } from "react-redux";

const getLocalToken = () => {
  return localStorage.getItem("token");
};

export async function getBetbyToken() {
  const startTime = Date.now();
  const newToken = await asyncSocketEmit("get:betby_details");
  return newToken?.data?.token;
}

export default function Betby() {
  const navigate = useNavigate();

  const [betbyDetails, setBetbyDetails] = useState(null);
  const [BTRendererReady, setBTRendererReady] = useState(false);
  const { isAuth } = useSelector((state) => state.auth);
  let flag = false;
  console.log("getLocalToken()", getLocalToken());
  useImportScript("https://kingbet.sptpub.com/bt-renderer.min.js");

  // useImportScript("https://ui.invisiblesport.com/bt-renderer.min.js");

  useEffect(() => {
    if (!betbyDetails?.token && isAuth && !flag) {
      flag = true;
      socketFunc(getLocalToken()).emit("get:betby_details", (res) => {
        if (res.data && res?.data?.data) {
          setBetbyDetails(
            (prev) =>
              (prev = {
                brand_id: res?.data?.data?.brandId,
                token: res?.data?.data?.token,
              })
          );

          return;
        }

        if (res.error) {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, res?.error?.message);
        }
      });
      return;
    }
    if (!betbyDetails && !isAuth) {
      setBetbyDetails({
        brand_id: "2268791968538693632",
        token: null,
      });
    }

    return () => {};
  }, [betbyDetails, isAuth]);

  useEffect(() => {
    const checkBtRenederInterval = setInterval(() => {
      if (window.BTRenderer) {
        console.log({
          BTRendererAvailable: window.BTRenderer ? true : false,
          lobbyDetailsReady: betbyDetails ? true : false,
        });
        clearInterval(checkBtRenederInterval);
        setBTRendererReady((prev) => true);
      }
    }, 10);
    return () => {
      clearInterval(checkBtRenederInterval);
    };
  }, []);

  useEffect(() => {
    const BTRenderer = window.BTRenderer;

    if (BTRenderer) {
      const bt = new BTRenderer();
      let BTSession;
      if ((isAuth && betbyDetails?.token) || betbyDetails) {
        if (BTSession) {
          BTSession.kill();
        }

        BTSession = bt.initialize({
          ...betbyDetails,
          themeName: "default",

          lang: "en",
          target: document.getElementById("betby"),
          betSlipOffsetTop: 0,
          betslipZIndex: 999,
          cssUrls: [],
          fontFamilies: ["Montserrat, sans-serif", "Roboto, sans-serif"],
          onTokenExpired: async function () {
            console.log("BT:: token expired called");
            return await getBetbyToken();
          },
          // onRouteChange: function () {},
          onLogin: function () {
            // console.log("BT:: login called");
            setBetbyDetails(null);
            return navigate("/sign-in");
          },
          onRegister: function () {
            // console.log("BT:: Register called");
            setBetbyDetails(null);
            return navigate("/mobile-number");
          },
          onSessionRefresh: async function () {
            console.log("BT:: Session refresh called");
            return await getBetbyToken();
          },
          onBetSlipStateChange: function () {
            // console.log("BT:: Betslip called");
          },
          onRecharge: function () {
            return navigate("/cashier");
          },
        });
      }

      // console.log({ BTSession });
      return () => {
        if (BTSession) {
          BTSession.kill();
        }
      };
    }
    // }, 100);
  }, [BTRendererReady, betbyDetails?.token, isAuth]);

  return (
    <main className="main pb-0">
      <div className="live_casino_page live otherdesign Beforehomepage">
        <div className="header-bg"></div>

        {!betbyDetails && (
          <div className="custom_placeholder_parent">
            <div className="custom_placeholder_child">
              <Spinner animation="grow" variant="primary" />
              <Spinner animation="grow" variant="secondary" />
              <Spinner animation="grow" variant="danger" />
            </div>
          </div>
        )}
        <div id="betby"></div>
      </div>
    </main>
  );
}
