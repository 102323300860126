import axios from "axios";
import { SPORTS_DATA, ODDS_DATA, BET_MATCHES, USER_BETS } from "./type";
import { API_URLS } from "../../config/apiUrls";
const sportsBaseUrl = process.env.REACT_APP_SPORTS_HOST;

// get all sports data (SOCKETS)
export const setSportsData = (data) => (dispatch) => {
  dispatch({
    type: SPORTS_DATA,
    payload: data,
  });
};

//get all match odds (SOCKETS)
export const setOddsData = (data) => (dispatch) => {
  dispatch({
    type: ODDS_DATA,
    payload: data,
  });
};
export const getBetMatches = (payload) => (dispatch) => {
  try {
    const result = axios.get(
      `${sportsBaseUrl}${API_URLS.SPORTS.GET_BET_MATCHES}?status=${payload.status}`,
      {
        headers: {
          "xu-auth": localStorage.getItem("token"),
        },
      }
    );

    result.then((res) => {
      dispatch({
        type: BET_MATCHES,
        payload: res?.data?.data?.matches,
      });
    });
  } catch (err) {
    console.debug(err);
  }
};
export const getUserBet = (payload) => (dispatch) => {
  try {
    const result = axios.get(
      `${sportsBaseUrl}${API_URLS.SPORTS.GET_USER_BETS}?skip=${payload.skip}&pagesize=${payload.pageSize}&refmatchid=${payload?.refmatchid}`,
      {
        headers: {
          "xu-auth": localStorage.getItem("token"),
        },
      }
    );
    result.then((res) => {
      dispatch({
        type: USER_BETS,
        payload: res?.data?.data?.bets,
      });
    });
  } catch (err) {
    console.log(err);
  }
};
