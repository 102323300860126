import React from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { casinoTabs } from "../../lib/data";
import CasinoCard from "./CasinoCard";
import { AiOutlineLeftCircle } from "react-icons/ai";
// import Evolution from "../../assets/images/Evolution.png";
import Ezugi from "../../assets/images/Ezugi.png";
// import Supernova from "../../assets/images/Supernova.png";
import XPG from "../../assets/images/Xpg.png";
import MainSlider from "../../containers/TestSlider";
import { wcocasinotab } from "../../lib/data/wcocasinotab.data";
import { AiOutlineRightCircle } from "react-icons/ai";
import { useRef } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
const TestPageContent = () => {
  const { banners } = useSelector((state) => state.app);
  console.log("banners", banners);
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const ref = useRef(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <main className="main">
      <div className="mainBanner">
        <MainSlider banners={banners} />
      </div>

      <div className="Live_casino_tabs hot_games">
        <Tabs defaultActiveKey="Roulette" id="uncontrolled-tab-example">
          <Tab eventKey="Roulette" title="Roulette">
            <div className="Live-Casino">
              <Container>
                <h4 className="live_heading ev-heading">
                  <img src="./images/provider/evolution-heading.png" />
                </h4>
                <div className="listings">
                  <span className="left-arrows" onClick={() => scroll(-20)}>
                    <AiOutlineLeftCircle />
                  </span>

                  <div className="Evolution_Games">
                    {Array.isArray(Object.values(casinoTabs?.roulettetab)) &&
                      Object.values(casinoTabs?.roulettetab)
                        .slice(0, 8)
                        .map((game, idx) => (
                          <CasinoCard key={"casinogame" + idx} data={game} />
                        ))}
                  </div>
                  <span
                    className="left-arrows right-arrow"
                    onClick={() => scroll(20)}
                  >
                    <AiOutlineRightCircle />
                  </span>
                </div>

                <h4 className="live_heading">
                  <img src="./images/provider/ezugi-heading.png" />
                </h4>
                <div className="listings">
                  <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span>
                  <div className="Evolution_Games">
                    {Array.isArray(Object.values(casinoTabs?.roulettetab)) &&
                      Object.values(casinoTabs?.roulettetab)
                        .slice(8, 20)
                        .map((game, idx) => (
                          <CasinoCard key={"casinogame" + idx} data={game} />
                        ))}
                  </div>
                  <span className="left-arrows right-arrow">
                    <AiOutlineRightCircle />
                  </span>
                </div>

                <h4 className="live_heading ev-heading">
                  <img src="./images/provider/super-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {Array.isArray(Object.values(casinoTabs?.roulettetab)) &&
                    Object.values(casinoTabs?.roulettetab)
                      .slice(26, 30)
                      .map((game, idx) => {
                        // console.log("game 88", game);
                        return (
                          <CasinoCard key={"casinogame" + idx} data={game} />
                        );
                      })}
                </div>

                <h4 className="live_heading">
                  <img src="./images/provider/xpg-heading.png" />
                </h4>
                <div className="listings">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  <div className="Evolution_Games">
                    {Array.isArray(Object.values(casinoTabs?.roulettetab)) &&
                      Object.values(casinoTabs?.roulettetab)
                        .slice(21, 24)
                        .map((game, idx) => (
                          <CasinoCard key={"casinogame" + idx} data={game} />
                        ))}
                  </div>
                  {/* <span className="left-arrows right-arrow">
                    <AiOutlineRightCircle />
                  </span> */}
                </div>
              </Container>
            </div>
          </Tab>
          <Tab eventKey="Baccarat" className="bacccart" title="Baccarat">
            <div className="Live-Casino">
              <Container>
                <h4 className="live_heading ev-heading">
                  <img src="./images/provider/evolution-heading.png" />
                </h4>
                <div className="listings">
                  <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span>
                  <div className="Evolution_Games">
                    {Array.isArray(Object.values(casinoTabs?.baccarattab)) &&
                      Object.values(casinoTabs?.baccarattab)
                        .slice(0, 4)
                        .map((game, idx) => (
                          <CasinoCard key={"casinogame" + idx} data={game} />
                        ))}
                  </div>
                  <span className="left-arrows right-arrow">
                    <AiOutlineRightCircle />
                  </span>
                </div>

                {/* <h4 className="live_heading">
                  <img className="ezugi_logo" src={Ezugi} alt="" />
                </h4> */}
                <h4 className="live_heading">
                  <img src="./images/provider/ezugi-heading.png" />
                </h4>
                <div className="listings">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  <div className="Evolution_Games">
                    {Array.isArray(Object.values(casinoTabs?.baccarattab)) &&
                      Object.values(casinoTabs?.baccarattab)
                        .slice(4, 7)
                        .map((game, idx) => (
                          <CasinoCard key={"casinogame" + idx} data={game} />
                        ))}
                  </div>
                  {/* <span className="left-arrows right-arrow">
                    <AiOutlineRightCircle />
                  </span> */}
                </div>

                <h4 className="live_heading ev-heading ">
                  {" "}
                  <img src="./images/provider/super-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {Array.isArray(Object.values(casinoTabs?.baccarattab)) &&
                    Object.values(casinoTabs?.baccarattab)
                      .slice(7, 8)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                {/* <h4 className="live_heading">
                  <img className="ezugi_logo" src={XPG} alt="" />
                </h4> */}
                <h4 className="live_heading">
                  <img src="./images/provider/xpg-heading.png" />
                </h4>
                <div className="listings">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  <div className="Evolution_Games">
                    {Array.isArray(Object.values(casinoTabs?.baccarattab)) &&
                      Object.values(casinoTabs?.baccarattab)
                        .slice(8, 9)
                        .map((game, idx) => (
                          <CasinoCard key={"casinogame" + idx} data={game} />
                        ))}
                  </div>
                  {/* <span className="left-arrows right-arrow">
                    <AiOutlineRightCircle />
                  </span> */}
                </div>
              </Container>
            </div>
          </Tab>
          <Tab eventKey="Andar Bahar" title="Andar Bahar">
            <div className="Live-Casino">
              <Container>
                <h4 className="live_heading ev-heading">
                  <img src="./images/provider/evolution-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.AndarBahartab)) &&
                    Object.values(casinoTabs?.AndarBahartab)
                      .slice(4, 6)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading">
                  <img src="./images/provider/ezugi-heading.png" />
                </h4>

                <div className="Evolution_Games">
                  {Array.isArray(Object.values(casinoTabs?.AndarBahartab)) &&
                    Object.values(casinoTabs?.AndarBahartab)
                      .slice(0, 2)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading ev-heading">
                  {" "}
                  <img src="./images/provider/super-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.AndarBahartab)) &&
                    Object.values(casinoTabs?.AndarBahartab)
                      .slice(2, 4)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading">
                  <img src="./images/provider/xpg-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.AndarBahartab)) &&
                    Object.values(casinoTabs?.AndarBahartab)
                      .slice(6, 8)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
              </Container>
            </div>
          </Tab>
          <Tab eventKey="Poker" title="Poker">
            <div className="Live-Casino">
              <Container>
                {/* <h4 className="live_head ev-headinging">
                  <img src="./images/provider/evolution-heading.png" />
                </h4> */}
                {/* <div className="Evolution_Games">
                <span className="left-arrows">
                      <AiOutlineLeftCircle />
                    </span>
                  {Array.isArray(Object.values(casinoTabs?.TeenPattitab)) &&
                    Object.values(casinoTabs?.TeenPattitab)
                      .slice(0, 9)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div> */}
                <h4 className="live_heading">
                  <img src="./images/provider/ezugi-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.TeenPattitab)) &&
                    Object.values(casinoTabs?.TeenPattitab)
                      .slice(0, 2)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading ev-heading">
                  {" "}
                  <img src="./images/provider/super-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.TeenPattitab)) &&
                    Object.values(casinoTabs?.TeenPattitab)
                      .slice(6, 9)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading">
                  <img src="./images/provider/xpg-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.TeenPattitab)) &&
                    Object.values(casinoTabs?.TeenPattitab)
                      .slice(2, 6)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
              </Container>
            </div>
          </Tab>
          <Tab eventKey="Blackjack" title="Blackjack">
            <div className="Live-Casino">
              <Container>
                <h4 className="live_heading ev-heading">
                  <img src="./images/provider/evolution-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.Blackjacktab)) &&
                    Object.values(casinoTabs?.Blackjacktab)
                      .slice(10, 13)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading">
                  <img src="./images/provider/ezugi-heading.png" />
                </h4>
                <div className="listings">
                  <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span>
                  <div className="Evolution_Games">
                    {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                    {Array.isArray(Object.values(casinoTabs?.Blackjacktab)) &&
                      Object.values(casinoTabs?.Blackjacktab)
                        .slice(2, 7)
                        .map((game, idx) => (
                          <CasinoCard key={"casinogame" + idx} data={game} />
                        ))}
                  </div>
                  <span className="left-arrows right-arrow">
                    <AiOutlineRightCircle />
                  </span>
                </div>
                <h4 className="live_heading ev-heading">
                  {" "}
                  <img src="./images/provider/super-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                      <AiOutlineLeftCircle />
                    </span> */}
                  {Array.isArray(Object.values(casinoTabs?.Blackjacktab)) &&
                    Object.values(casinoTabs?.Blackjacktab)
                      .slice(9, 10)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading">
                  <img src="./images/provider/xpg-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.Blackjacktab)) &&
                    Object.values(casinoTabs?.Blackjacktab)
                      .slice(13, 16)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
              </Container>
            </div>
          </Tab>
          <Tab eventKey="TeenPatti" title="TeenPatti">
            <div className="Live-Casino">
              <Container>
                {/* <h4 className="live_head ev-headinging">
                  <img src="./images/provider/evolution-heading.png" />
                </h4> */}
                {/* <div className="Evolution_Games">
                <span className="left-arrows">
                      <AiOutlineLeftCircle />
                    </span>
                  {Array.isArray(Object.values(casinoTabs?.TeenPattitab)) &&
                    Object.values(casinoTabs?.TeenPattitab)
                      .slice(0, 9)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div> */}
                <h4 className="live_heading">
                  <img src="./images/provider/ezugi-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.TeenPattitab)) &&
                    Object.values(casinoTabs?.TeenPattitab)
                      .slice(0, 2)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading ev-heading">
                  {" "}
                  <img src="./images/provider/super-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.TeenPattitab)) &&
                    Object.values(casinoTabs?.TeenPattitab)
                      .slice(6, 9)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading">
                  <img src="./images/provider/xpg-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.TeenPattitab)) &&
                    Object.values(casinoTabs?.TeenPattitab)
                      .slice(2, 6)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
              </Container>
            </div>
          </Tab>
          <Tab eventKey="32Card" title="32 Card">
            <div className="Live-Casino">
              <Container>
                {/* <h4 className="live_head ev-headinging">
                  <img src="./images/provider/evolution-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span>
                  {Array.isArray(Object.values(casinoTabs?.thirtytwoCardtab)) &&
                    Object.values(casinoTabs?.thirtytwoCardtab)
                      .slice(0, 9)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div> */}
                <h4 className="live_heading">
                  <img src="./images/provider/ezugi-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.thirtytwoCardtab)) &&
                    Object.values(casinoTabs?.thirtytwoCardtab)
                      .slice(0, 1)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading ev-heading">
                  <img src="./images/provider/super-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.thirtytwoCardtab)) &&
                    Object.values(casinoTabs?.thirtytwoCardtab)
                      .slice(1, 2)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                {/* <h4 className="live_heading">
                  <img src="./images/provider/xpg-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span>
                  {Array.isArray(Object.values(casinoTabs?.thirtytwoCardtab)) &&
                    Object.values(casinoTabs?.thirtytwoCardtab)
                      .slice(0, 9)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div> */}
              </Container>
            </div>
          </Tab>
          <Tab eventKey="Seven" title="Lucky 7">
            <div className="Live-Casino">
              <Container>
                {/* <h4 className="live_head ev-headinging">
                  <img src="./images/provider/evolution-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span>
                  {Array.isArray(Object.values(casinoTabs?.sevenLuckytab)) &&
                    Object.values(casinoTabs?.sevenLuckytab)
                      .slice(0, 9)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div> */}
                <h4 className="live_heading">
                  <img src="./images/provider/ezugi-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.sevenLuckytab)) &&
                    Object.values(casinoTabs?.sevenLuckytab)
                      .slice(0, 1)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading ev-heading">
                  {" "}
                  <img src="./images/provider/super-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.sevenLuckytab)) &&
                    Object.values(casinoTabs?.sevenLuckytab)
                      .slice(1, 2)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                {/* <h4 className="live_heading">
                  <img src="./images/provider/xpg-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span>
                  {Array.isArray(Object.values(casinoTabs?.sevenLuckytab)) &&
                    Object.values(casinoTabs?.sevenLuckytab)
                      .slice(0, 9)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div> */}
              </Container>
            </div>
          </Tab>
          <Tab eventKey="Dices" title="Sicbo">
            <div className="Live-Casino">
              <Container>
                <h4 className="live_heading ev-heading">
                  <img src="./images/provider/evolution-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.sicbotab)) &&
                    Object.values(casinoTabs?.sicbotab)
                      .slice(0, 3)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading">
                  <img src="./images/provider/ezugi-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.sicbotab)) &&
                    Object.values(casinoTabs?.sicbotab)
                      .slice(3, 4)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading ev-heading">
                  {" "}
                  <img src="./images/provider/super-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.sicbotab)) &&
                    Object.values(casinoTabs?.sicbotab)
                      .slice(5, 6)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading">
                  <img src="./images/provider/xpg-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.sicbotab)) &&
                    Object.values(casinoTabs?.sicbotab)
                      .slice(4, 5)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
              </Container>
            </div>
          </Tab>
          <Tab eventKey="BetNumber" title="Bet on number">
            <div className="Live-Casino">
              <Container>
                <h4 className="live_heading ev-heading">
                  <img src="./images/provider/evolution-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.BetOnNumberTab)) &&
                    Object.values(casinoTabs?.BetOnNumberTab)
                      .slice(1, 4)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                <h4 className="live_heading">
                  <img src="./images/provider/ezugi-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.BetOnNumberTab)) &&
                    Object.values(casinoTabs?.BetOnNumberTab)
                      .slice(0, 1)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
                {/* <h4 className="live_heading">Supernowa</h4> */}
                {/* <div className="Evolution_Games">
                <span className="left-arrows">
                      <AiOutlineLeftCircle />
                    </span>
                  {Array.isArray(Object.values(casinoTabs?.BetOnNumberTab)) &&
                    Object.values(casinoTabs?.BetOnNumberTab)
                      .slice(0, 9)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div> */}
                <h4 className="live_heading">
                  <img src="./images/provider/xpg-heading.png" />
                </h4>
                <div className="Evolution_Games">
                  {/* <span className="left-arrows">
                    <AiOutlineLeftCircle />
                  </span> */}
                  {Array.isArray(Object.values(casinoTabs?.BetOnNumberTab)) &&
                    Object.values(casinoTabs?.BetOnNumberTab)
                      .slice(4, 5)
                      .map((game, idx) => (
                        <CasinoCard key={"casinogame" + idx} data={game} />
                      ))}
                </div>
              </Container>
            </div>
          </Tab>
          {/* <Tab eventKey="Sports" title="Sports">
            <div className="Live-Casino">
              <Container></Container>
            </div>
          </Tab> */}
        </Tabs>
        <div className="Spribe_Games">
          <Container>
            <div className="listings">
              <h4 className="live_heading">Indian Games</h4>
              <div className="Evolution_Games">
                {Array.isArray(Object.values(casinoTabs?.indianGames)) &&
                  Object.values(casinoTabs?.indianGames)
                    .slice(0, 8)
                    .map((game, idx) => (
                      <CasinoCard key={"casinogame" + idx} data={game} />
                    ))}
              </div>
            </div>
            <div className="listings">
              <h4 className="live_heading">Spribe Games</h4>
              <div className="Evolution_Games">
                {Array.isArray(Object.values(casinoTabs?.spribeGames)) &&
                  Object.values(casinoTabs?.spribeGames)
                    .slice(0, 8)
                    .map((game, idx) => (
                      <CasinoCard key={"casinogame" + idx} data={game} />
                    ))}
              </div>
            </div>
            <div className="listings">
              <h4 className="live_heading">Popular Games</h4>
              <div className="Evolution_Games">
                {Array.isArray(Object.values(casinoTabs?.popularGames)) &&
                  Object.values(casinoTabs?.popularGames)
                    .slice(0, 8)
                    .map((game, idx) => (
                      <CasinoCard key={"casinogame" + idx} data={game} />
                    ))}
              </div>
            </div>
            <div className="listings">
              <h4 className="live_heading">Slots Games</h4>
              <div className="Evolution_Games">
                {Array.isArray(Object.values(casinoTabs?.slotsgames)) &&
                  Object.values(casinoTabs?.slotsgames)
                    .slice(0, 8)
                    .map((game, idx) => (
                      <CasinoCard key={"casinogame" + idx} data={game} />
                    ))}
              </div>
            </div>
            <div className="listings pt-5">
              <div className="Provider_Games">
                {Array.isArray(Object.values(casinoTabs?.Proivdergames)) &&
                  Object.values(casinoTabs?.Proivdergames)
                    .slice(0, 8)
                    .map((game, idx) => (
                      <CasinoCard key={"casinogame" + idx} data={game} />
                    ))}
              </div>
            </div>
            <div className="listings pt-4">
              <h5 className="live_heading-center mb-4">
                Available Payment Methods
              </h5>
              <div className="Payments_Games">
                {Array.isArray(Object.values(casinoTabs?.PaymentMethod)) &&
                  Object.values(casinoTabs?.PaymentMethod)
                    .slice(0, 8)
                    .map((game, idx) => (
                      <CasinoCard key={"casinogame" + idx} data={game} />
                    ))}
              </div>
            </div>
          </Container>
        </div>
      </div>
    </main>
  );
};
export default TestPageContent;
