import React from "react";

export default function CasinoCard({ data }) {
  return (
    <a href={data.href || "#"} className="casino_games">
      <div className="shadow rounded position-relative">
        <img src={data?.cover?.src} alt={data?.cover?.alt || ""} />
      </div>
    </a>
  );
}
