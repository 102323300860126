import io from "socket.io-client";

export const socket = io(process.env.REACT_APP_SOCKET_URL, {
  path: "/public/",
  transports: ["websocket", "polling"],
  auth: {
    token: localStorage.getItem("token"),
  },
});

export const socketFunc = (token) =>
  io(process.env.REACT_APP_SOCKET_URL, {
    path: "/public/",
    transports: ["websocket", "polling"],
    auth: {
      token: token || localStorage.getItem("token"),
    },
  });

/**
 *
 * @param {*} inputData[]
 * [ eventName: string, data: object ]
 * @returns {*} { status: "", data: object }
 */

export function asyncSocketEmit(...inputData) {
  return new Promise(function (resolve, reject) {
    socket.emit(...inputData, (result) => {
      resolve(result);
    });
    // socket.on(eventName, (result) => {
    //   socket.off(eventName);
    //   resolve(result);
    // });
    setTimeout(reject, 1000);
  });
}
