import { SPORTS_DATA, ODDS_DATA } from "./type";

const initialState = {
  sportsData: sessionStorage.getItem("sportsData")
    ? JSON.parse(sessionStorage.getItem("sportsData"))
    : null,
  oddsData: sessionStorage.getItem("oddsData")
    ? JSON.parse(sessionStorage.getItem("oddsData"))
    : null,
};

export default (state = initialState, actions) => {
  let { payload, type } = actions;
  switch (type) {
    case SPORTS_DATA:
      sessionStorage.setItem("sportsData", JSON.stringify(payload));
      return {
        ...state,
        sportsData: payload,
      };
    case ODDS_DATA:
      sessionStorage.setItem("oddsData", JSON.stringify(payload));
      return {
        ...state,
        oddsData: payload,
      };
    default:
      return state;
  }
};
