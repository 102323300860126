import {
  BALANCE_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  EXPOSURE_AMOUNT,
} from "./types";
import {
  axiosService,
  setUserToken,
  removeUserToken,
} from "../../utils/axiosService";
import { API_URLS } from "../../config/apiUrls";
import { Toaster } from "../../utils/toaster";
import { APP_CONST } from "../../config/const";
import axios from "axios";
import { AxiosError } from "axios";
import { socketFunc } from "../../utils/socketUtil";

const API_HOST_V2 = process.env.REACT_APP_API_HOST_V2;
const sportsBaseUrl = process.env.REACT_APP_SPORTS_HOST;

// login user
export const loginUser = (payload) => (dispatch) => {
  try {
    const result = axiosService.post(API_URLS.AUTH.LOGIN_USER, payload);
    result
      .then((res) => {
        if (res.data.error === false) {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Login Success");
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data.data,
          });
          setUserToken(res.data.data?.token);
        } else {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, "Login Failed");
        }
      })
      .catch((err) => {
        const passwordError =
          err?.response?.data?.message &&
          err?.response?.data?.message.toLowerCase().indexOf("username") > -1 &&
          err?.response?.data?.message;

        Toaster(APP_CONST.TOAST_TYPE.ERROR, passwordError || "Login Failed");
      });
  } catch (err) {
    Toaster(APP_CONST.TOAST_TYPE.ERROR, "Login Failed");
    console.debug(err);
  }
};

//register User

export const registerUser = (paylaod, navigate) => (dispatch) => {
  try {
    const result = axiosService.post(
      API_URLS.AUTH.REGISTER_USER,
      JSON.stringify(paylaod),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    result
      .then((res) => {
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Player added");
        localStorage.removeItem("mobile_details");
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res?.data?.data,
        });
        setUserToken(res.data.data?.token);
      })
      .catch((err) => {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
      });
  } catch (err) {
    console.debug(err);
  }
};

// send mobile otp
export const sendOtpToMobile = (paylaod) => (dispatch) => {
  try {
    const result = axiosService.post(
      API_URLS.AUTH.SEND_OTP_TO_MOBILE,
      JSON.stringify(paylaod)
    );
    return result;
  } catch (err) {
    console.debug(err);
  }
};

// verify otp
export const mobileVerification = (paylaod) => (dispatch) => {
  try {
    const result = axiosService.post(
      API_URLS.AUTH.MOBILE_VERIFICATION,
      JSON.stringify(paylaod)
    );
    return result;
  } catch (err) {
    console.debug(err);
  }
};

export const balanceUpdate = (data) => (dispatch) => {
  dispatch({
    type: BALANCE_SUCCESS,
    payload: data,
  });
};

export const logoutUser = () => (dispatch) => {
  try {
    axiosService.get(API_URLS.AUTH.SIGN_OUT);
  } catch (err) {
    console.log(err);
  }
  dispatch({
    type: LOGOUT,
  });
  removeUserToken();
};

export const getExposureAmount = () => (dispatch) => {
  try {
    const result = axios.get(
      `${sportsBaseUrl}${API_URLS.AUTH.GET_EXPOSURE_AMOUNT}`,
      {
        headers: {
          "xu-auth": localStorage.getItem("token"),
        },
      }
    );
    result.then((res) => {
      dispatch({
        type: EXPOSURE_AMOUNT,
        payload: res?.data?.data?.exposureAmount,
      });
    });
  } catch (err) {
    console.debug(err);
  }
};

export const forgotPassword = (paylaod) => {
  try {
    return axiosService.post(API_URLS.AUTH.FORGOT_PASSWORD, paylaod);
  } catch (err) {
    console.debug(err);
  }
};

export const resetPassword = (payload) => {
  try {
    return axiosService.post(API_URLS.AUTH.RESET_PASSWORD, payload);
  } catch (err) {
    console.debug(err);
  }
};

export const updatePassword = (paylaod) => {
  try {
    return axiosService.post(API_URLS.AUTH.UPDATE_PASSWORD, paylaod);
  } catch (err) {
    console.debug(err);
  }
};

export const pageVisit = async (payload) => {
  try {
    const result = await axiosService.post(API_URLS.AUTH.PAGE_VISIT, payload);
    return result;
  } catch (err) {
    console.debug(err);
  }
};
// Demo login
export const demoLogin = () => async (dispatch) => {
  try {
    const demoLoginResponse = await axiosService.post(
      `${API_HOST_V2}${API_URLS.AUTH.DEMO_LOGIN}`
    );
    if (demoLoginResponse && demoLoginResponse?.data?.error === false) {
      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Login Success");
      socketFunc(demoLoginResponse?.data?.data?.token).emit(
        "get:balance",
        (data) => {
          if (data?.status === "success") {
            dispatch(balanceUpdate(data));
          }
        }
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: demoLoginResponse.data.data,
      });
      setUserToken(demoLoginResponse.data.data.token);
    }
  } catch (err) {
    if (err instanceof AxiosError) {
      Toaster(
        APP_CONST.TOAST_TYPE.ERROR,
        err?.response?.data?.message || "Login Failed"
      );
    }
  }
};
