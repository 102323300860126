import React from "react";

import Table from "react-bootstrap/Table";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
const ProfitAndLoss = () => {
  return (
    <div>
      <HeaderAfterLogin />
      <div className="main" id="payment_page">
        <div className="table-responsive">
          <Table striped bordered hover variant="light">
            <thead>
              <tr>
                <th className="profit_loss_heading">Date</th>
                <th className="profit_loss_heading">Sr no</th>
                <th className="profit_loss_heading">Credit</th>
                <th className="profit_loss_heading">Debit</th>
                <th className="profit_loss_heading">Balance</th>
                <th className="profit_loss_heading">----</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ProfitAndLoss;
