import { APP_DETAILS, BANK_DETAILS, BANNERS } from "./types";
import { APP_CONST } from "../../config/const";
const initialState = {
  appDetails:
    localStorage.getItem("appDetails") !== null
      ? JSON.parse(localStorage.getItem("appDetails"))
      : null,
  bankDetails: null,
  banners:
    localStorage.getItem("banners") !== null
      ? JSON.parse(localStorage.getItem("banners"))
      : null,
  organisationStatus:
    localStorage.getItem("organisation_status") ||
    APP_CONST.ORGANISATION_STATUS.Live,
};

export default (state = initialState, actions) => {
  const { payload, type } = actions;
  switch (type) {
    case APP_DETAILS:
      localStorage.setItem("appDetails", JSON.stringify(payload));
      localStorage.setItem(
        "organisation_status",
        payload?.BRAND_STATUS || APP_CONST.ORGANISATION_STATUS.Live
      );
      return {
        ...state,
        appDetails: payload,
        organisationStatus: payload?.BRAND_STATUS,
      };
    case BANK_DETAILS:
      return {
        ...state,
        bankDetails: payload,
      };
    case BANNERS:
      payload.sort((a, b) => a.order - b.order);
      localStorage.setItem("banners", JSON.stringify(payload));
      return {
        ...state,
        banners: payload,
      };
    default:
      return state;
  }
};
