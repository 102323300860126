import SpeedRoulette from "../../assets/images/roulette/home/speed-roulette1.jpg";
import DiamondRoulette from "../../assets/images/roulette/home/diamond-roulette1.jpg";

import NamasteRoulette from "../../assets/images/roulette/home/mamaste-roulette1.jpg";
import TurkishRoulette from "../../assets/images/roulette/home/turkish-roulette.jpg";
import AutoRoulette from "../../assets/images/roulette/home/auto-roulette.jpg";
import SpeedAutoRoulette from "../../assets/images/roulette/home/speed-auto-roulette.jpg";
import OracleRoulette2 from "../../assets/images/roulette/home/oracle-roulette-2.jpg";
import PortomasoRoulette1 from "../../assets/images/roulette/home/portomaso-roulette-1.jpg";
import ItalianRoulette from "../../assets/images/roulette/home/italian-roulette.jpg";
import SpanishRoulette from "../../assets/images/roulette/home/spanish-roulette1.jpg";
import RussianRoulette from "../../assets/images/roulette/home/russian-roulette.jpg";
import FiestaRoulette from "../../assets/images/roulette/home/fiesta-roulette1.jpg";
import PrestigeAutoRoulette from "../../assets/images/roulette/home/prestige-auto-oulette.jpg";

import Baccaratpro from "../../assets/images/baccarat/home/Baccartpro.jpg";
import Nocommissiobaccarat from "../../assets/images/baccarat/home/nocommissionbaccarat.jpg";
import Knockoutbaccarat from "../../assets/images/baccarat/home/Knockoutbaccart.jpg";
import Super6baccarat from "../../assets/images/baccarat/home/Super6baccart.jpg";
import Marinabaccarat from "../../assets/images/baccarat/home/Fromcasinomarinabaccart.jpg";
import Marina02baccarat from "../../assets/images/baccarat/home/Marina02baccart.jpg";
import Salsabaccarat from "../../assets/images/baccarat/home/speedcricketbaccarat.jpg";
import Baccarat from "../../assets/images/baccarat/home/Baccart.jpg";
import Baccaratpro02 from "../../assets/images/baccarat/home/Baccart02.jpg";
import SpeedBaccarat from "../../assets/images/baccarat/home/SpeedBaccart.jpg";
import Marina03baccarat from "../../assets/images/baccarat/home/marina03baccart.jpg";

import AndarBaharLive from "../../assets/images/andarbahar/home/Andarbahar.jpg";
import OTTAndarBahar from "../../assets/images/andarbahar/home/OTTAndarbahar.jpg";
import ULtimateAB from "../../assets/images/andarbahar/home/UltimateAndarbahar.jpg";

import Blackjack from "../../assets/images/blackjack/home/Blackjack.jpg";
import GoldBlackjack from "../../assets/images/blackjack/home/Goldblackjack.jpg";
import BlackjackPro from "../../assets/images/blackjack/home/Blackjackpro.jpg";
import VIPBlackjack from "../../assets/images/blackjack/home/VIPblackjack.jpg";
import BlackjackAutoSplit from "../../assets/images/blackjack/home/Blackjackautosplit.jpg";
import TurkceBlackjack from "../../assets/images/blackjack/home/turkceblackjack.jpg";
import LiveBlackjack from "../../assets/images/blackjack/home/Liveblackjack.jpg";
import RumbaBlackjack from "../../assets/images/blackjack/home/Rumbablackjack.jpg";
import DiamondBlackjack from "../../assets/images/blackjack/home/Diamondblackjack.jpg";
import Rumba02Blackjack from "../../assets/images/blackjack/home/Rumba2blackjack.jpg";
import VipBlackjack from "../../assets/images/blackjack/home/VIPblackjack.jpg";
import Vip02Blackjack from "../../assets/images/blackjack/home/VIPblackjack2.jpg";
import TurkishBlackjack from "../../assets/images/blackjack/home/turkceblackjack.jpg";
import BlackjackLive from "../../assets/images/blackjack/home/Liveblackjack.jpg";
import PlatinumBlackjack from "../../assets/images/blackjack/home/platinumblackjack.jpg";

import TeenPatti01 from "../../assets/images/poker/home/Onedayteenpatti.jpg";
import TeenPatti02 from "../../assets/images/poker/home/teenpatti.jpg";
import TeenPatti03 from "../../assets/images/poker/home/betonteenpatti.jpg";
import TeenPatti04 from "../../assets/images/poker/home/Casinoholdem.jpg";
import TeenPatti05 from "../../assets/images/poker/home/Sidebetcity.jpg";
import TeenPatti06 from "../../assets/images/poker/home/Taxasholdem.jpg";
import TeenPatti07 from "../../assets/images/poker/home/casinoholdemblackjack.jpg";
import TeenPatti08 from "../../assets/images/poker/home/Dragontiger.jpg";

import SicBo from "../../assets/images/sicbo/Sicbo.jpg";
import SuperSicBo from "../../assets/images/sicbo/Supersicbo.jpg";
import UltimateSicBo from "../../assets/images/sicbo/Ultimatesicbo.jpg";

import thirtytwoCards from "../../assets/images/card/home/card32.jpg";

import sevenLucky from "../../assets/images/lucky7/home/Lucky7.jpg";

export const slotsData = {
  slots1: {
    redirectUrl: "/casino/ezugi/gonzoquest",
    imgUrl: "./images/slots/SlotsImg2.png",
    name: "Gonzo Treasure Hunts",
  },
  slots2: {
    redirectUrl: "/casino/ezugi/asgardianstones",
    imgUrl: "./images/slots/asgardianstones.png",
    name: "Asgardian Stones",
  },
  slots3: {
    redirectUrl: "/casino/ezugi/secrets",
    imgUrl: "./images/slots/secrets-of-atlantis.png",
    name: "Secrets of Atlantis",
  },
  slots4: {
    redirectUrl: "/casino/ezugi/grandwheel",
    imgUrl: "./images/slots/grand-wheel.png",
    name: "Grand Wheel",
  },
  slots5: {
    redirectUrl: "/casino/ezugi/Strike777",
    imgUrl: "./images/slots/777strike.png",
    name: "777 Strike",
  },
  slots6: {
    redirectUrl: "/casino/ezugi/Nights1000",
    imgUrl: "./images/slots/10001-nights.png",
    name: "10001 Nights",
  },
  slots7: {
    redirectUrl: "/casino/ezugi/CashUltimate",
    imgUrl: "./images/slots/cash_ultimate.png",
    name: "Cash Ultimate",
  },
  slots8: {
    redirectUrl: "/casino/ezugi/CashVolt",
    imgUrl: "./images/slots/cash_volt.png",
    name: "Cash Volt",
  },
  slots9: {
    redirectUrl: "/casino/ezugi/DazzleMegaways",
    imgUrl: "./images/slots/dazzle_me_megaways.png",
    name: "Dazzle Me Megaways",
  },
  slots10: {
    redirectUrl: "/casino/ezugi/DivinieFortuneMegaways",
    imgUrl: "./images/slots/divine_fortune_megaways.png",
    name: "Divine Fortune Megaways",
  },
  slots11: {
    redirectUrl: "/casino/ezugi/Dynamiteriches",
    imgUrl: "./images/slots/dynamite_riches.png",
    name: "Dynamite Riches",
  },
  slots12: {
    redirectUrl: "/casino/ezugi/FortuneHouse",
    imgUrl: "./images/slots/fortunehouse.png",
    name: "Fotune House",
  },
  slots13: {
    redirectUrl: "/casino/ezugi/FruitShopMegaways",
    imgUrl: "./images/slots/Fruit_Shop_Megaways.png",
    name: "Fruit Shop Megaways",
  },
  slots14: {
    redirectUrl: "/casino/ezugi/GoldenCryptex",
    imgUrl: "./images/slots/Golden_Cryptex.png",
    name: "Golden Cryptex",
  },
  slots15: {
    redirectUrl: "/casino/ezugi/GoldenTsar",
    imgUrl: "./images/slots/Golden_Tsar.png",
    name: "Golden Tsar",
  },
  slots16: {
    redirectUrl: "/casino/ezugi/GorillaKingdom",
    imgUrl: "./images/slots/Gorilla_Kingdom.png",
    name: "Gorilla Kingdom",
  },
  slots17: {
    redirectUrl: "/casino/ezugi/MystryReels",
    imgUrl: "./images/slots/Mystery_Reels_Megaways.png",
    name: "Mystery Reels Megaways",
  },
  slots18: {
    redirectUrl: "/casino/ezugi/RageoftheSea",
    imgUrl: "./images/slots/Rage_Of_The_Seas.png",
    name: "Rage Of The Seas",
  },
  slots19: {
    redirectUrl: "/casino/ezugi/ReelKingMegaways",
    imgUrl: "./images/slots/Reel_King_Mega.png",
    name: "Reel King Mega",
  },
  slots20: {
    redirectUrl: "/casino/ezugi/TurnFortune",
    imgUrl: "./images/slots/Turn_Your_Fortune.png",
    name: "Turn Your Fortune",
  },
  slots21: {
    redirectUrl: "/casino/ezugi/TwinSpinMegaways",
    imgUrl: "./images/slots/Twin_Spin_Megaways.png",
    name: "Twin Spin Megaways",
  },
  slots22: {
    redirectUrl: "/casino/ezugi/WillysChills",
    imgUrl: "./images/slots/Willys_Hot_Chillies.png",
    name: "Willys Hot Chillies",
  },
  slots23: {
    redirectUrl: "/casino/ezugi/ChineseTreasures",
    imgUrl: "./images/slots/Chinese-Treasures.png",
    name: "Chinese Treasures",
  },
  slots24: {
    redirectUrl: "/casino/ezugi/DragonLuck",
    imgUrl: "./images/slots/Dragon's-Luck.png",
    name: "Dragon's Luck",
  },
  slots25: {
    redirectUrl: "/casino/ezugi/DragonFire",
    imgUrl: "./images/slots/Dragon's-Fire.png",
    name: "Dragon's Fire",
  },
  slots26: {
    redirectUrl: "/casino/ezugi/DragonLuckDeluxe",
    imgUrl: "./images/slots/Dragon's-Luck-Deluxe.png",
    name: "Dragon's Luck Deluxe",
  },
  slots27: {
    redirectUrl: "/casino/ezugi/DragonLuckReel",
    imgUrl: "./images/slots/Dragon's-Luck-Power-Reels.png",
    name: "Dragon's Luck Power Reels",
  },
  slots28: {
    redirectUrl: "/casino/ezugi/EpicJourney",
    imgUrl: "./images/slots/Epic-Journey.png",
    name: "Epic Journey",
  },
  slots29: {
    redirectUrl: "/casino/ezugi/GodWealth",
    imgUrl: "./images/slots/God-Of-Wealth.png",
    name: "God Of Wealth",
  },
  slots30: {
    redirectUrl: "/casino/ezugi/LuckyFortune",
    imgUrl: "./images/slots/Lucky-Fortune-Cat.png",
    name: "Lucky Fortune Cat",
  },
  slots31: {
    redirectUrl: "/casino/ezugi/MagicGate",
    imgUrl: "./images/slots/Magic-Gate.png",
    name: "Magic Gate",
  },
  slots32: {
    redirectUrl: "/casino/ezugi/GoldenTemple",
    imgUrl: "./images/slots/Golden-Temple.png",
    name: "Golden Temple",
  },
  slots33: {
    redirectUrl: "/casino/ezugi/PhoenixPower",
    imgUrl: "./images/slots/Phoenix-Fire-Power-Reels.png",
    name: "Phoenix Fire Power Reels",
  },
  slots34: {
    redirectUrl: "/casino/ezugi/GoldenOffer",
    imgUrl: "./images/slots/Golden-Offer.png",
    name: "Golden Offer",
  },
  slots35: {
    redirectUrl: "/casino/ezugi/RAsLegend",
    imgUrl: "./images/slots/RA's-Legend.png",
    name: "RA's Legend",
  },
  slots36: {
    redirectUrl: "/casino/ezugi/GonzoMegawaya",
    imgUrl: "./images/slots/Gonzo's-Quest-Megaways.png",
    name: "Gonzo's Quest Megaways",
  },
  slots37: {
    redirectUrl: "/casino/ezugi/RainbowJAcket",
    imgUrl: "./images/slots/Rainbow-Jackpots.png",
    name: "Rainbow Jackpots",
  },
  slots38: {
    redirectUrl: "/casino/ezugi/MegaPyramid",
    imgUrl: "./images/slots/Mega-Pyramid.png",
    name: "Mega Pyramid",
  },
  slots39: {
    redirectUrl: "/casino/ezugi/Jimi_Hendrix",
    imgUrl: "./images/slots/Jimi_Hendrix.png",
    name: "Jimi Hendrix",
  },
  slots40: {
    redirectUrl: "/casino/ezugi/Jumanji",
    imgUrl: "./images/slots/Jumanji.png",
    name: "Jumanji",
  },
  slots41: {
    redirectUrl: "/casino/ezugi/MotorheadVideoSlot",
    imgUrl: "./images/slots/Motorhead_Video_Slot.png",
    name: "Motorhead Video Slot",
  },
  slots42: {
    redirectUrl: "/casino/ezugi/Starburst",
    imgUrl: "./images/slots/Starburst.png",
    name: "Starburst",
  },
  slots43: {
    redirectUrl: "/casino/ezugi/WarofGod",
    imgUrl: "./images/slots/War-Of-Gods.png",
    name: "War Of Gods",
  },
  slots44: {
    redirectUrl: "/casino/ezugi/Christmas",
    imgUrl: "./images/slots/Aloha-Christmas.png",
    name: "Aloha Christmas",
  },
  slots45: {
    redirectUrl: "/casino/ezugi/ClusterPays",
    imgUrl: "./images/slots/Aloha-Cluster-Pays.png",
    name: "Aloha Cluster Pays",
  },
  slots46: {
    redirectUrl: "/casino/ezugi/AncientsBlessing",
    imgUrl: "./images/slots/Ancients-Blessing.png",
    name: "Ancients Blessing",
  },
  slots47: {
    redirectUrl: "/casino/ezugi/AurumCodex",
    imgUrl: "./images/slots/Aurum-Codex.png",
    name: "Aurum Codex",
  },
  slots48: {
    redirectUrl: "/casino/ezugi/GunsNRoses",
    imgUrl: "./images/slots/Guns_N_Roses.png",
    name: "Guns N Roses",
  },
  slots49: {
    redirectUrl: "/casino/ezugi/BorisandBoo",
    imgUrl: "./images/slots/Betty-Boris-and-Boo.png",
    name: "Betty Boris and Boo",
  },
  slots50: {
    redirectUrl: "/casino/ezugi/WildWest",
    imgUrl: "./images/slots/Wild-Wild-West-The-Great-Train-Heist.png",
    name: "Wild West The Great Train Heist",
  },
  slots51: {
    redirectUrl: "/casino/ezugi/SteamTower",
    imgUrl: "./images/slots/Steam-Tower.png",
    name: "Steam Tower",
  },
  slots52: {
    redirectUrl: "/casino/ezugi/ZeusLightning",
    imgUrl: "./images/slots/Zeus-Lightning-Power-Reels.png",
    name: "Zeus Lightning Power Reels",
  },
  slots53: {
    redirectUrl: "/casino/ezugi/WingsofRa",
    imgUrl: "./images/slots/Wings-of-Ra.png",
    name: "Wings of Ra",
  },
  slots54: {
    redirectUrl: "/casino/ezugi/Trillionaire",
    imgUrl: "./images/slots/Trillionaire.png",
    name: "Trillionaire",
  },
  slots55: {
    redirectUrl: "/casino/ezugi/TreasureMine",
    imgUrl: "./images/slots/Treasure-Mine.png",
    name: "Treasure Mine",
  },
  slots56: {
    redirectUrl: "/casino/ezugi/ThorVengeance",
    imgUrl: "./images/slots/Thor's-Vengeance.png",
    name: "Thor's Vengeance",
  },
  slots57: {
    redirectUrl: "/casino/ezugi/RegalStreak",
    imgUrl: "./images/slots/Regal-Streak.png",
    name: "Regal Streak",
  },
  slots58: {
    redirectUrl: "/casino/ezugi/RegalBeasts",
    imgUrl: "./images/slots/Regal-Beasts.png",
    name: "Regal Beasts",
  },
  slots59: {
    redirectUrl: "/casino/ezugi/ReelKeeper",
    imgUrl: "./images/slots/Reel-Keeper.png",
    name: "Reel Keeper",
  },
  slots60: {
    redirectUrl: "/casino/ezugi/RainbowJackpotsPowerLines",
    imgUrl: "./images/slots/Rainbow-Jackpots-Power-Lines.png",
    name: "Rainbow Jackpots Power Lines",
  },
  slots61: {
    redirectUrl: "/casino/ezugi/PiggyRichesMegaways",
    imgUrl: "./images/slots/Piggy-Riches-Megaways.png",
    name: "Piggy Riches Megaways",
  },
  slots62: {
    redirectUrl: "/casino/ezugi/JingleBells",
    imgUrl: "./images/slots/Jingle-Bells.png",
    name: "Jingle Bells",
  },
  slots63: {
    redirectUrl: "/casino/ezugi/JackinaPot",
    imgUrl: "./images/slots/Jack-in-a-Pot.png",
    name: "Jack in a Pot",
  },
  slots64: {
    redirectUrl: "/casino/ezugi/FiveStar",
    imgUrl: "./images/slots/Five-Star.png",
    name: "Five Star",
  },
  slots65: {
    redirectUrl: "/casino/ezugi/BountyRaid",
    imgUrl: "./images/slots/Bounty-Raid.png",
    name: "Bounty Raid",
  },
  slots66: {
    redirectUrl: "/casino/ezugi/MysteryReels2",
    imgUrl: "./images/slots/Mystery-Reels.png",
    name: "Mystery Reels",
  },
  slots67: {
    redirectUrl: "/casino/ezugi/DiamondBlitz",
    imgUrl: "./images/slots/Diamond-Blitz.png",
    name: "Diamond Blitz",
  },
  slots68: {
    redirectUrl: "/casino/ezugi/DragonFireMegaways",
    imgUrl: "./images/slots/Dragon's-Fire-Megaways.png",
    name: "Dragon's Fire Megaways",
  },
  slots69: {
    redirectUrl: "/casino/ezugi/Rome",
    imgUrl: "./images/slots/Rome-The-Golden-Age.png",
    name: "Rome-The Golden Age",
  },
  slots70: {
    redirectUrl: "/casino/ezugi/ReelRush",
    imgUrl: "./images/slots/Reel-Rush.png",
    name: "Reel Rush",
  },
  slots71: {
    redirectUrl: "/casino/ezugi/BloodSuckers",
    imgUrl: "./images/slots/Blood-Suckers.png",
    name: "Blood Suckers",
  },
  slots72: {
    redirectUrl: "/casino/ezugi/BloodSuckers2",
    imgUrl: "./images/slots/Blood-Suckers-2.png",
    name: "Blood Suckers 2",
  },
  slots73: {
    redirectUrl: "/casino/ezugi/Pyramid",
    imgUrl: "./images/slots/Pyramid-Quest-for-Immortality.png",
    name: "Pyramid Quest for Immortality",
  },
  slots74: {
    redirectUrl: "/casino/ezugi/ButterflyStaxx",
    imgUrl: "./images/slots/Butterfly-Staxx.png",
    name: "Butterfly Staxx",
  },
  slots75: {
    redirectUrl: "/casino/ezugi/ButterflyStaxx2",
    imgUrl: "./images/slots/Butterfly-Staxx-2.png",
    name: "Butterfly Staxx 2",
  },
  slots76: {
    redirectUrl: "/casino/ezugi/PiggyRiches",
    imgUrl: "./images/slots/Piggy-Riches.png",
    name: "Piggy Riches",
  },
  slots77: {
    redirectUrl: "/casino/ezugi/DarkKing",
    imgUrl: "./images/slots/Dark-King-Forbidden-Riches.png",
    name: "Dark King Forbidden Riches",
  },
  slots78: {
    redirectUrl: "/casino/ezugi/JingleBellsPowerReels",
    imgUrl: "./images/slots/Jingle-Bells-Power-Reels.png",
    name: "Jingle Bells Power Reels",
  },
  slots79: {
    redirectUrl: "/casino/ezugi/Dazzle",
    imgUrl: "./images/slots/Dazzle-Me.png",
    name: "Dazzle Me",
  },
  slots80: {
    redirectUrl: "/casino/ezugi/JackHammer",
    imgUrl: "./images/slots/Jack-Hammer.png",
    name: "Jack Hammer",
  },
  slots81: {
    redirectUrl: "/casino/ezugi/JackHammer2",
    imgUrl: "./images/slots/Jack-Hammer-2-Fishy-Business.png",
    name: "Jack Hammer 2 Fishy Business",
  },
  slots82: {
    redirectUrl: "/casino/ezugi/DeadorAlive",
    imgUrl: "./images/slots/Dead-or-Alive.png",
    name: "Dead or Alive",
  },
  slots83: {
    redirectUrl: "/casino/ezugi/DeadorAlive2",
    imgUrl: "./images/slots/Dead-or-Alive-2.png",
    name: "Dead or Alive 2",
  },
  slots84: {
    redirectUrl: "/casino/ezugi/DeadorAlive2FeatureBuy",
    imgUrl: "./images/slots/Dead-or-Alive-2-Feature-Buy.png",
    name: "Dead or Alive 2 Feature Buy",
  },
  slots85: {
    redirectUrl: "/casino/ezugi/JackandtheBeanstalk",
    imgUrl: "./images/slots/Jack-and-the-Beanstalk.png",
    name: "Jack and the Beanstalk",
  },
  slots86: {
    redirectUrl: "/casino/ezugi/JingleSpin",
    imgUrl: "./images/slots/Jingle-Spin.png",
    name: "Jingle Spin",
  },
  slots87: {
    redirectUrl: "/casino/ezugi/GordonRamsayHellsKitchen",
    imgUrl: "./images/slots/Gordon_Ramsay_Hells_Kitchen.png",
    name: "Gordon Ramsay Hells Kitchen",
  },
  slots88: {
    redirectUrl: "/casino/ezugi/Elements",
    imgUrl: "./images/slots/Elements-The-Awakening.png",
    name: "Elements The Awakening",
  },
  slots89: {
    redirectUrl: "/casino/ezugi/GonzosQuest",
    imgUrl: "./images/slots/Gonzos_Quest.png",
    name: "Gonzos Quest",
  },
  slots90: {
    redirectUrl: "/casino/ezugi/FinnandtheSwirlySpin",
    imgUrl: "./images/slots/Finn-and-the-Swirly-Spin.png",
    name: "Finn and the Swirly Spin",
  },
  slots91: {
    redirectUrl: "/casino/ezugi/FruitShop",
    imgUrl: "./images/slots/Fruit-Shop.png",
    name: "Fruit Shop",
  },
  // slots92: {
  //   redirectUrl: "/casino/ezugi/DazzleMegaways",
  //   imgUrl: "./images/slots/Dazzle-Me.png",
  //   name: "Dazzle Me",
  // },
  slots93: {
    redirectUrl: "/casino/ezugi/DoubleStacks",
    imgUrl: "./images/slots/Double-Stacks.png",
    name: "Double Stacks",
  },
  slots94: {
    redirectUrl: "/casino/ezugi/FairytaleLegends",
    imgUrl: "./images/slots/Fairytale-Legends-Red-Riding-Hood.png",
    name: "Fairytale Legends Red Riding Hood",
  },
  slots95: {
    redirectUrl: "/casino/ezugi/Flowers",
    imgUrl: "./images/slots/Flowers.png",
    name: "Flowers",
  },
  slots96: {
    redirectUrl: "/casino/ezugi/FruitShopChristmasEdition",
    imgUrl: "./images/slots/Fruit-Shop-Christmas-Edition.png",
    name: "Fruit Shop Christmas Edition",
  },
  slots97: {
    redirectUrl: "/casino/ezugi/Hotline",
    imgUrl: "./images/slots/Hotline.png",
    name: "Hotline",
  },
  slots98: {
    redirectUrl: "/casino/ezugi/Hotline2",
    imgUrl: "./images/slots/Hotline-2.png",
    name: "Hotline 2",
  },
  slots99: {
    redirectUrl: "/casino/ezugi/TheInvisibleMan",
    imgUrl: "./images/slots/The_Invisible_Man.png",
    name: "The Invisible Man",
  },
  slots100: {
    redirectUrl: "/casino/ezugi/IrishPotLuck",
    imgUrl: "./images/slots/Irish-Pot-Luck.png",
    name: "Irish Pot Luck",
  },
  slots101: {
    redirectUrl: "/casino/ezugi/FruitSpin",
    imgUrl: "./images/slots/Fruit-Spin.png",
    name: "Fruit Spin",
  },
  slots102: {
    redirectUrl: "/casino/ezugi/JungleSpirit",
    imgUrl: "./images/slots/Jungle-Spirit-Call-of-the-Wild.png",
    name: "Jungle Spirit Call of the Wild",
  },
  slots103: {
    redirectUrl: "/casino/ezugi/KoiPrincess",
    imgUrl: "./images/slots/Koi-Princess.png",
    name: "Koi Princess",
  },
  slots104: {
    redirectUrl: "/casino/ezugi/DriveMultiplierMayhem",
    imgUrl: "./images/slots/Drive-Multiplier-Mayhem.png",
    name: "Drive Multiplier Mayhem",
  },
  slots105: {
    redirectUrl: "/casino/ezugi/RageoftheSeas",
    imgUrl: "./images/slots/Rage-of-the-Seas.png",
    name: "Rage of the Seas",
  },
  slots106: {
    redirectUrl: "/casino/ezugi/RichesofMidgard",
    imgUrl: "./images/slots/Riches-of-Midgard-Land-and-Expand.png",
    name: "Riches of Midgard Land and Expand",
  },
  slots107: {
    redirectUrl: "/casino/ezugi/ScruffyDuck",
    imgUrl: "./images/slots/Scruffy-Duck.png",
    name: "Scruffy Duck",
  },
  slots108: {
    redirectUrl: "/casino/ezugi/SecretoftheStones",
    imgUrl: "./images/slots/Secret-of-the-Stones.png",
    name: "Secret of the Stones",
  },
  slots109: {
    redirectUrl: "/casino/ezugi/SecretsofChristmas",
    imgUrl: "./images/slots/Secrets-of-Christmas.png",
    name: "Secret of Christmas",
  },
  slots110: {
    redirectUrl: "/casino/ezugi/SerengetiKings",
    imgUrl: "./images/slots/Serengeti-Kings.png",
    name: "Serengeti Kings",
  },
  slots111: {
    redirectUrl: "/casino/ezugi/SpaceWars",
    imgUrl: "./images/slots/Space-Wars.png",
    name: "Space Wars",
  },
  slots112: {
    redirectUrl: "/casino/ezugi/Victorious",
    imgUrl: "./images/slots/Victorious.png",
    name: "Victorious",
  },
  slots113: {
    redirectUrl: "/casino/ezugi/Warlords",
    imgUrl: "./images/slots/Warlords-Crystal-of-Power.png",
    name: "Warlords Crystal of Power",
  },
  slots114: {
    redirectUrl: "/casino/ezugi/WildWater",
    imgUrl: "./images/slots/Wild-Water.png",
    name: "Wild Water",
  },
  slots115: {
    redirectUrl: "/casino/ezugi/WildWorlds",
    imgUrl: "./images/slots/Wild-Worlds.png",
    name: "Wild Worlds",
  },
  slots116: {
    redirectUrl: "/casino/ezugi/Squad4",
    imgUrl: "./images/slots/4-Squad.png",
    name: "4 Squad",
  },
  slots117: {
    redirectUrl: "/casino/ezugi/Families5",
    imgUrl: "./images/slots/5-Families.png",
    name: "5 Families",
  },
  slots118: {
    redirectUrl: "/casino/ezugi/AgentRoyale",
    imgUrl: "./images/slots/Agent-Royale.png",
    name: "Agent Royale",
  },
  slots118: {
    redirectUrl: "/casino/ezugi/ArcadeBomb",
    imgUrl: "./images/slots/Arcade-Bomb.png",
    name: "Arcade Bomb",
  },
  slots119: {
    redirectUrl: "/casino/ezugi/Atlantis",
    imgUrl: "./images/slots/Atlantis.png",
    name: "Atlantis",
  },
  slots120: {
    redirectUrl: "/casino/ezugi/AztecSpins",
    imgUrl: "./images/slots/Aztec-Spins.png",
    name: "Aztec Spins",
  },
  slots121: {
    redirectUrl: "/casino/ezugi/CinderellaBall",
    imgUrl: "./images/slots/Cinderella`s-Ball.png",
    name: "Cinderella`s Ball",
  },
  slots122: {
    redirectUrl: "/casino/ezugi/CirquedelaFortune",
    imgUrl: "./images/slots/Cirque-de-la-Fortune.png",
    name: "Cirque de la Fortune",
  },
  slots123: {
    redirectUrl: "/casino/ezugi/ClashoftheBeasts",
    imgUrl: "./images/slots/Clash-of-the-Beasts.png",
    name: "Clash of the Beasts",
  },
  slots124: {
    redirectUrl: "/casino/ezugi/CrazyGenie",
    imgUrl: "./images/slots/Crazy-Genie.png",
    name: "Crazy Genie",
  },
  slots125: {
    redirectUrl: "/casino/ezugi/CrystalMirror",
    imgUrl: "./images/slots/Crystal-Mirror.png",
    name: "Crystal Mirror",
  },
  slots126: {
    redirectUrl: "/casino/ezugi/DaVinciMystery",
    imgUrl: "./images/slots/Da-Vinci`s-Mystery.png",
    name: "Da-Vinci`s Mystery",
  },
  slots127: {
    redirectUrl: "/casino/ezugi/DevilNumber",
    imgUrl: "./images/slots/Devil`s-Number.png",
    name: "Devil`s Number",
  },
  slots128: {
    redirectUrl: "/casino/ezugi/DiceDice",
    imgUrl: "./images/slots/Dice-Dice-Dice.png",
    name: "Dice Dice Dice",
  },
  slots129: {
    redirectUrl: "/casino/ezugi/FiveStarsPowerReels",
    imgUrl: "./images/slots/Five-Stars-Power-Reels.png",
    name: "Five Stars Power Reels",
  },
  slots130: {
    redirectUrl: "/casino/ezugi/GemsGoneWildPowerReels",
    imgUrl: "./images/slots/Gems-Gone-Wild-Power-Reels.png",
    name: "Gems Gone Wild Power Reels",
  },
  slots131: {
    redirectUrl: "/casino/ezugi/GemsGoneWild",
    imgUrl: "./images/slots/Gems-Gone-Wild.png",
    name: "Gems Gone Wild",
  },
  slots132: {
    redirectUrl: "/casino/ezugi/GoldenLeprechaunMegaways",
    imgUrl: "./images/slots/Golden-Leprechaun-Megaways.png",
    name: "GoldenLeprechaun Megaways",
  },
  slots132: {
    redirectUrl: "/casino/ezugi/HoardofPoseidon",
    imgUrl: "./images/slots/Hoard-of-Poseidon.png",
    name: "Hoard of Poseidon",
  },
  slots133: {
    redirectUrl: "/casino/ezugi/JewelScarabs",
    imgUrl: "./images/slots/Jewel-Scarabs.png",
    name: "Jewel Scarabs",
  },
  slots134: {
    redirectUrl: "/casino/ezugi/LegendofAthena",
    imgUrl: "./images/slots/Legend-of-Athena.png",
    name: "Legend of Athena",
  },
  slots135: {
    redirectUrl: "/casino/ezugi/LuckyHalloween",
    imgUrl: "./images/slots/Lucky-Halloween.png",
    name: "Lucky Halloween",
  },
  slots136: {
    redirectUrl: "/casino/ezugi/LuckyLittleDevil",
    imgUrl: "./images/slots/Lucky-Little-Devil.png",
    name: "Lucky Little Devil",
  },
  slots137: {
    redirectUrl: "/casino/ezugi/LuckyValentine",
    imgUrl: "./images/slots/Lucky-Valentine.png",
    name: "Lucky Valentine",
  },
  slots138: {
    redirectUrl: "/casino/ezugi/LuckyWizard",
    imgUrl: "./images/slots/Lucky-Wizard.png",
    name: "Lucky Wizard",
  },
  slots139: {
    redirectUrl: "/casino/ezugi/Masquerade",
    imgUrl: "./images/slots/Masquerade.png",
    name: "Masquerade",
  },
  slots140: {
    redirectUrl: "/casino/ezugi/MayanGods",
    imgUrl: "./images/slots/Mayan-Gods.png",
    name: "Mayan Gods",
  },
  slots141: {
    redirectUrl: "/casino/ezugi/MegaDragon",
    imgUrl: "./images/slots/Mega-Dragon.png",
    name: "Mega Dragon",
  },
  slots142: {
    redirectUrl: "/casino/ezugi/MegaJade",
    imgUrl: "./images/slots/Mega-Jade.png",
    name: "Mega Jade",
  },
  slots143: {
    redirectUrl: "/casino/ezugi/MegaRise",
    imgUrl: "./images/slots/Mega-Rise.png",
    name: "Mega Rise",
  },
  slots144: {
    redirectUrl: "/casino/ezugi/MultiplierRiches",
    imgUrl: "./images/slots/Multiplier-Riches.png",
    name: "Multiplier Riches",
  },
  slots145: {
    redirectUrl: "/casino/ezugi/MysteryReelsMegaways",
    imgUrl: "./images/slots/Mystery_Reels_Megaways.png",
    name: "Mystery Reels Megaways",
  },
  slots146: {
    redirectUrl: "/casino/ezugi/NinjaWays",
    imgUrl: "./images/slots/Ninja-Ways.png",
    name: "Ninja Ways",
  },
  slots147: {
    redirectUrl: "/casino/ezugi/PathofDestiny",
    imgUrl: "./images/slots/Path-of-Destiny.png",
    name: "Path of Destiny",
  },
  slots148: {
    redirectUrl: "/casino/ezugi/PersianFortune",
    imgUrl: "./images/slots/Persian-Fortune.png",
    name: "Persian Fortune",
  },
  slots149: {
    redirectUrl: "/casino/ezugi/ReelHeist",
    imgUrl: "./images/slots/Reel-Heist.png",
    name: "Reel Heist",
  },
  slots150: {
    redirectUrl: "/casino/ezugi/SnowWild",
    imgUrl: "./images/slots/Snow-Wild-And-The-7-Features.png",
    name: "Snow Wild And The 7 Features",
  },
  slots151: {
    redirectUrl: "/casino/ezugi/RobinHoodWildForest",
    imgUrl: "./images/slots/Robin-Hood`s-Wild-Forest.png",
    name: "Robin Hood`s Wild Forest",
  },
  slots152: {
    redirectUrl: "/casino/ezugi/SylvanSpirits",
    imgUrl: "./images/slots/Sylvan-Spirits.png",
    name: "Sylvan Spirits",
  },
  slots153: {
    redirectUrl: "/casino/ezugi/TheGreatestTrainRobbery",
    imgUrl: "./images/slots/The-Greatest-Train-Robbery.png",
    name: "The Greatest Train Robbery",
  },
  slots154: {
    redirectUrl: "/casino/ezugi/TheWildHatter",
    imgUrl: "./images/slots/The-Wild-Hatter.png",
    name: "The Wild Hatter",
  },
  slots155: {
    redirectUrl: "/casino/ezugi/ThorLightning",
    imgUrl: "./images/slots/Thor`s-Lightning.png",
    name: "Thor`s Lightning",
  },
  slots156: {
    redirectUrl: "/casino/ezugi/TikiFruits",
    imgUrl: "./images/slots/Tiki-Fruits.png",
    name: "Tiki Fruits",
  },
  slots157: {
    redirectUrl: "/casino/ezugi/TotemLightning",
    imgUrl: "./images/slots/Totem-Lightning.png",
    name: "Totem Lightning",
  },
  slots158: {
    redirectUrl: "/casino/ezugi/VaultCracker",
    imgUrl: "./images/slots/Vault-Cracker.png",
    name: "Vault Cracker",
  },
  slots159: {
    redirectUrl: "/casino/ezugi/VaultofAnubis",
    imgUrl: "./images/slots/Vault-of-Anubis.png",
    name: "Vault of Anubis",
  },
  slots160: {
    redirectUrl: "/casino/ezugi/WellofWishes",
    imgUrl: "./images/slots/Well-of-Wishes.png",
    name: "Well of Wishes",
  },
  slots161: {
    redirectUrl: "/casino/ezugi/WildCatsMultiline",
    imgUrl: "./images/slots/Wild-Cats-Multiline.png",
    name: "Wild Cats Multiline",
  },
  slots162: {
    redirectUrl: "/casino/ezugi/WildOClock",
    imgUrl: "./images/slots/Wild-O`Clock.png",
    name: "Wild O`Clock",
  },
  slots163: {
    redirectUrl: "/casino/ezugi/WildChest",
    imgUrl: "./images/slots/Wild-Wild-Chest.png",
    name: "Wild Wild Chest",
  },
  slots164: {
    redirectUrl: "/casino/ezugi/WinEscalator",
    imgUrl: "./images/slots/Win-Escalator.png",
    name: "Win Escalator",
  },
  slots165: {
    redirectUrl: "/casino/ezugi/YucatanMystery",
    imgUrl: "./images/slots/Yucatan`s-Mystery.png",
    name: "Yucatan`s Mystery",
  },
};

export const casinoGames = {
  casino1: {
    redirectUrl: "/casino/evolution/RouletteLive",
    imgUrl: "./images/roulette/casino03.png",
    name: "Instant Roulette Live",
  },
  casino2: {
    redirectUrl: "/casino/evolution/RouletteVip",
    imgUrl: "./images/roulette/casino02.png",
    name: "Roulette Vip",
  },
  casino3: {
    redirectUrl: "/casino/evolution/AmericanRoulette",
    imgUrl: "./images/roulette/casino04.png",
    name: "American Roulette",
  },
  casino4: {
    redirectUrl: "/casino/evolution/AmericanRouletteFirstPerson",
    imgUrl: "./images/roulette/casino08.png",
    name: "American Roulette FirstPerson",
  },
  casino5: {
    redirectUrl: "/casino/evolution/LightningRouletteFirstPerson",
    imgUrl: "./images/roulette/casino01111.png",
    name: "Lightning Roulette FirstPerson",
  },
  casino6: {
    redirectUrl: "/casino/evolution/LightingRoulette",
    imgUrl: "./images/roulette/casino015.png",
    name: "Lightning Roulette ",
  },
  casino7: {
    redirectUrl: "/casino/evolution/doubleballroulette",
    imgUrl: "./images/roulette/casino070.PNG",
    name: "Double ball roulette ",
  },
  casino8: {
    redirectUrl: "/casino/ezugi/namasteroulette",
    imgUrl: "./images/roulette/casinio28.png",
    name: "Namaste Roulette ",
  },
  casino9: {
    redirectUrl: "/casino/evolution/UltimateRoulette",
    imgUrl: "./images/roulette/ultimate-roulette.png",
    name: "Ultimate Roulette ",
  },
  casino10: {
    redirectUrl: "/casino/evolution/PortmasoRoulette",
    imgUrl: "./images/roulette/casino013.png",
    name: "From Portomaso Casino Roulette ",
  },
  casino11: {
    redirectUrl: "/casino/ezugi/marinaroulette",
    imgUrl: "./images/roulette/casino019.png",
    name: "From Casino Marina roulette ",
  },
  casino12: {
    redirectUrl: "/casino/ezugi/OracleRoulette360",
    imgUrl: "./images/roulette/casino030.png",
    name: "Oracle Roulette360 ",
  },
  casino13: {
    redirectUrl: "/casino/ezugi/diamondroulette",
    imgUrl: "./images/roulette/casino028.png",
    name: "Diamond roulette ",
  },
  casino14: {
    redirectUrl: "/casino/ezugi/speedroulette",
    imgUrl: "./images/roulette/casino029.PNG",
    name: "Speed roulette ",
  },
  casino15: {
    redirectUrl: "/casino/ezugi/oracleRoulette",
    imgUrl: "./images/roulette/casino010.png",
    name: "Oracle Roulette ",
  },
  casino16: {
    redirectUrl: "/casino/ezugi/AutoRoulette1",
    imgUrl: "./images/roulette/casino026.png",
    name: "Auto Roulette ",
  },
  casino17: {
    redirectUrl: "/casino/ezugi/cumbiaRoulette",
    imgUrl: "./images/roulette/casino031.png",
    name: "Cumbia Roulette ",
  },
  casino18: {
    redirectUrl: "/casino/ezugi/PrestigeAutoRoulette",
    imgUrl: "./images/roulette/casino014.png",
    name: "Prestige AutoRoulette ",
  },
  casino19: {
    redirectUrl: "/casino/ezugi/FiestaRoulette",
    imgUrl: "./images/roulette/casino012.png",
    name: "Fiesta Roulette ",
  },
  casino20: {
    redirectUrl: "/casino/ezugi/autoRoulette",
    imgUrl: "./images/roulette/casino032.PNG",
    name: "Auto Roulette ",
  },
  casino21: {
    redirectUrl: "/casino/evolution/Roulette",
    imgUrl: "./images/roulette/casino078.PNG",
    name: "Roulette ",
  },
  casino22: {
    redirectUrl: "/casino/evolution/americanRouletLive",
    imgUrl: "./images/roulette/casino076.PNG",
    name: "American RouletLive ",
  },
  casino23: {
    redirectUrl: "/casino/evolution/autoRouletteLive",
    imgUrl: "./images/roulette/casino077.PNG",
    name: "Auto Roulette Live",
  },
  casino24: {
    redirectUrl: "/casino/evolution/TurkishsRoulettes",
    imgUrl: "./images/roulette/casino017.png",
    name: "Turkish Roulettes",
  },
  casino25: {
    redirectUrl: "/casino/evolution/Roulettelive",
    imgUrl: "./images/roulette/casino033.png",
    name: "Roulette live",
  },
  casino26: {
    redirectUrl: "/casino/ezugi/autORoulette",
    imgUrl: "./images/roulette/casino035.png",
    name: "Auto Roulette",
  },
  casino27: {
    redirectUrl: "/casino/ezgevo-first-person-baccarat",
    imgUrl: "./images/baccarat/casino040.png",
    name: "First person Baccarat ",
  },
  casino28: {
    redirectUrl: "/casino/evolution/Nocommissiobaccarat",
    imgUrl: "./images/baccarat/casino041.png",
    name: "Nocommission baccarat",
  },
  casino29: {
    redirectUrl: "/casino/evolution/Knockoutbaccarat",
    imgUrl: "./images/baccarat/casino042.png",
    name: "Knockout baccarat",
  },
  casino30: {
    redirectUrl: "/casino/evolution/baccaratLive",
    imgUrl: "./images/baccarat/casino0011.png",
    name: "Baccarat Live",
  },
  casino31: {
    redirectUrl: "/casino/ezg-casino-marina-baccarat-1",
    imgUrl: "./images/baccarat/casino031.png",
    name: "From casino Marina baccarat",
  },
  casino32: {
    redirectUrl: "/casino/ezugi/KnockOutbaccarat",
    imgUrl: "./images/baccarat/casino029.png",
    name: "KnockOut baccarat",
  },
  casino33: {
    redirectUrl: "/casino/ezugi/noCommisionBaccarat",
    imgUrl: "./images/baccarat/casino027.png",
    name: "No Commision Baccarat",
  },
  casino34: {
    redirectUrl: "/casino/sn-rng-baccarat",
    imgUrl: "./images/baccarat/casino080.png",
    name: "Supernowa Baccarat",
  },
  casino35: {
    redirectUrl: "/casino/ezugi/baccaratlive",
    imgUrl: "./images/baccarat/casino028.png",
    name: "xpg Speed Baccarat",
  },
  casino36: {
    redirectUrl: "/casino/evolution/Andarbahar",
    imgUrl: "./images/andarbahar/casino0112.png",
    name: "Andar bahar",
  },
  casino37: {
    redirectUrl: "/casino/evolution/Andarbaharlive",
    imgUrl: "./images/andarbahar/casino0113.png",
    name: "Andar bahar",
  },
  casino38: {
    redirectUrl: "/casino/ezugi/AndarBaharLive",
    imgUrl: "./images/andarbahar/casino032.png",
    name: "Andar bahar live",
  },
  casino39: {
    redirectUrl: "/casino/ezugi/AndarBaharlive",
    imgUrl: "./images/andarbahar/casino030.png",
    name: "Andar bahar live",
  },
  casino40: {
    redirectUrl: "/casino/ezugi/AndarBahar",
    imgUrl: "./images/andarbahar/casino091.PNG",
    name: "Andar bahar ",
  },
  casino41: {
    redirectUrl: "/casino/ezugi/GoaAndarBahar",
    imgUrl: "./images/andarbahar/casino090.PNG",
    name: "Goa Andar bahar ",
  },
  casino42: {
    redirectUrl: "/casino/ezugi/AndarbaharLive",
    imgUrl: "./images/andarbahar/casino0115.png",
    name: "Andar bahar Live ",
  },
  casino43: {
    redirectUrl: "/casino/ezg-one-day-teen-patti",
    imgUrl: "./images/poker/casino033.png",
    name: "One Day Teen Patti Classic",
  },
  casino44: {
    redirectUrl: "/casino/ezg-casino-holdem",
    imgUrl: "./images/poker/casino061.png",
    name: "Casino hold'em",
  },
  casino45: {
    redirectUrl: "/casino/ezugi/Poker",
    imgUrl: "./images/poker/casino093.png",
    name: "Poker ",
  },
  casino46: {
    redirectUrl: "/casino/ezugi/ThreeTeenPatti",
    imgUrl: "./images/poker/casino094.PNG",
    name: "Three Player TeenPatti ",
  },

  casino47: {
    redirectUrl: "/casino/ezugi/TeenPatti09",
    imgUrl: "./images/poker/casino092.PNG",
    name: "Teen Patti ",
  },
  casino48: {
    redirectUrl: "/casino/xpg/TeenPatti03",
    imgUrl: "./images/poker/casino034.png",
    name: "Teen Patti ",
  },
  casino49: {
    redirectUrl: "/casino/xpg/TeenPatti04",
    imgUrl: "./images/poker/casino031.png",
    name: "Bet on Teen Patti ",
  },
  casino50: {
    redirectUrl: "/casino/xpg/TeenPatti05",
    imgUrl: "./images/poker/casino035.png",
    name: "Teen Patti ",
  },
  casino51: {
    redirectUrl: "/casino/xpg/TeenPatti06",
    imgUrl: "./images/poker/casino032.png",
    name: "Three card poker ",
  },
  casino52: {
    redirectUrl: "/casino/evolution/Blackjack",
    imgUrl: "./images/blackjack/casino060.png",
    name: "Blackjack live",
  },
  casino53: {
    redirectUrl: "/casino/evolution/Blackjacklivee",
    imgUrl: "./images/blackjack/casino0222.png",
    name: "Blackjack live ",
  },
  casino54: {
    redirectUrl: "/casino/evolution/BlackJacklivee",
    imgUrl: "./images/blackjack/casino0223.png",
    name: "Blackjack live ",
  },
  casino55: {
    redirectUrl: "/casino/ezugi/vipBlackjack",
    imgUrl: "./images/blackjack/casino039.png",
    name: "Blackjack live ",
  },
  casino56: {
    redirectUrl: "/casino/ezugi/italianBlackjack",
    imgUrl: "./images/blackjack/casino053.png",
    name: "Italian Blackjack live ",
  },
  casino57: {
    redirectUrl: "/casino/ezugi/unlimitedblackjack",
    imgUrl: "./images/blackjack/casino043.png",
    name: "Unlimited Blackjack live ",
  },
  casino58: {
    redirectUrl: "/casino/ezugi/TurkceBlackjack",
    imgUrl: "./images/blackjack/casino044.png",
    name: "Turkce Blackjack  ",
  },
  casino59: {
    redirectUrl: "/casino/ezugi/rumbablackjack",
    imgUrl: "./images/blackjack/casino050.png",
    name: "Rumba Blackjack  ",
  },
  casino60: {
    redirectUrl: "/casino/supernowa/BlackjackLive",
    imgUrl: "./images/blackjack/casino099.png",
    name: "Blackjack  ",
  },
  casino61: {
    redirectUrl: "/casino/ezugi/GoldBlackjackLive",
    imgUrl: "./images/blackjack/casino042.png",
    name: "Gold Blackjack  ",
  },
  casino62: {
    redirectUrl: "/casino/ezugi/PlaastinumBlackjacksss",
    imgUrl: "./images/blackjack/casino0224.png",
    name: "Blackjack  ",
  },
  casino63: {
    redirectUrl: "/casino/ezugi/ItasasdlianBlackjackaasas",
    imgUrl: "./images/blackjack/casino0225.png",
    name: "Blackjack  ",
  },
  casino64: {
    redirectUrl: "/casino/ezugi/thirtytwoCards",
    imgUrl: "./images/card/casino057.png",
    name: "32 Cards  ",
  },
  casino65: {
    redirectUrl: "/casino/ezugi/thirtytwoCardss",
    imgUrl: "./images/card/casino0100.png",
    name: "32 Cards  ",
  },
  casino66: {
    redirectUrl: "/casino/ezugi/luckyseven",
    imgUrl: "./images/lucky7/casino056.png",
    name: "Lucky 7",
  },
  casino67: {
    redirectUrl: "/casino/supernowa/Lucky-7",
    imgUrl: "./images/lucky7/casino100.PNG",
    name: "Lucky 7 ",
  },
  casino68: {
    redirectUrl: "/casino/evolution/supersicbo",
    imgUrl: "./images/dice/casino060.png",
    name: "Super Sicbo ",
  },
  casino69: {
    redirectUrl: "/casino/evolution/craps",
    imgUrl: "./images/dice/casino0132.png",
    name: "Craps ",
  },
  casino70: {
    redirectUrl: "/casino/evolution/lightningdice",
    imgUrl: "./images/dice/casino062.png",
    name: "Lightning Dice ",
  },
  casino71: {
    redirectUrl: "/casino/ezugi/ultimatesicbo",
    imgUrl: "./images/dice/casino058.png",
    name: "Ultimate Sicbo ",
  },
  casino72: {
    redirectUrl: "/casino/supernowa/sicbo",
    imgUrl: "./images/dice/casino101.PNG",
    name: "Sicbo ",
  },
  casino73: {
    redirectUrl: "/casino/xpg/sicbodice04",
    imgUrl: "./images/dice/casino059.png",
    name: "Sicbo ",
  },
  casino74: {
    redirectUrl: "/casino/evolution/CrazyTime",
    imgUrl: "./images/games/casino039.png",
    name: "Crazy Time",
  },
  casino75: {
    redirectUrl: "/casino/evolution/DreamCatcher",
    imgUrl: "./images/games/casino0227.png",
    name: "Dream Catcher",
  },
  casino76: {
    redirectUrl: "/casino/ezugi/BetOnNumberHd",
    imgUrl: "./images/games/casino035.png",
    name: "Bet On Number Hd",
  },
  casino77: {
    redirectUrl: "/casino/xpg/WheelOfFortune",
    imgUrl: "./images/games/casino0228.png",
    name: "Wheel Of Fortune",
  },
  casino78: {
    redirectUrl: "/casino/xpg-dragon-roulette",
    imgUrl:  "./images/roulette/casino023.png",
    name: "dragon roulette",
 },
 TeenPatti03Card: {
  redirectUrl: "/casino/ezg-teen-patti-3-card",
imgUrl: "./images/poker/teen-patti-3-card.png",
  name: "Teen Patti 3 Card",
 
},
mines: {
  redirectUrl: "/casino/spribe/mines",
  name: "Mines",

  imgUrl: "./images/spribe/02.jpg",
},
dice: {
  redirectUrl: "/casino/spribe/dice",
  name: "Dice",
  imgUrl:  "./images/spribe/07.jpg",
},
aviator: {
  redirectUrl: "/casino/spribe/aviator",
  name: "Aviator",
  imgUrl:  "./images/spribe/03.png",
},
MonopolyLive: {
  redirectUrl: "/casino/evolution/MonopolyLive",
  name: "Monopoly live",
  imgUrl:  "./images/games/casino0229.png",
},
Dragontiger: {
  redirectUrl: "/casino/ezugi/Dragontiger",
  name: "Dragon Tiger live",

  imgUrl: "./images/popular/03.jpg",
},
baccaratB: {
  redirectUrl: "/casino/ezgevo-baccarat-b",
  name: "Speed baccarat b ",

  imgUrl:  "./images/baccarat/casino0011.PNG",
},
baccarat6: {
  redirectUrl: "/casino/ezg-super-6-baccarat",
  name: "Super 6 Baccarat ",

  imgUrl: "./images/baccarat/casino027.png", 
},
BaccaratEzugi: {
  redirectUrl: "/casino/ezg-baccarat",
  name: "Baccarat Live",

  imgUrl:  "./images/baccarat/baccaratB.PNG", 
},
SpeedCricketBaccarat: {
  redirectUrl: "/casino/ezg-speed-cricket-baccarat",
  name: "Speed Cricket Baccarat",

  imgUrl:  "./images/baccarat/Speedcricketbaccarta.png", 
},
VIPBaccarat: {
  redirectUrl: "/casino/ezg-vip-fortune-baccarat",
  name: "Speed Fortune Baccarat",

  imgUrl:"./images/baccarat/VIPBaccarat.png",
},
casin: {
    redirectUrl: "/casino/ezugi/OTTAndarBahar",
    imgUrl: "./images/andarbahar/casino030.png",
    name: "From Casino marina andar bahar",
  },
  UltimateAndarBahar: {
    redirectUrl: "/casino/ezugi/UltimateAndarBahar",
    name: "Ultimate Andar Bahar",

    imgUrl: "./images/andarbahar/ultimateandarbahar.png", 
  },
  AndarBaharClassic: {
    redirectUrl: "/casino/sn-classic-andar-bahar",
    name: "Classic Andar Bahar",

    imgUrl: "./images/andarbahar/AndarBaharClassic.png", 
  },
};

// export const casinoGamesList = {
//   casino1: {
//     redirectUrl: "/casino/ezugi/SpeedRoulette",
//     img: <img src={SpeedRoulette} />,
//     name: "Speed Roulette",
//   },
//   casino2: {
//     redirectUrl: "/casino/ezugi/DiamondRoulette",
//     img: <img src={DiamondRoulette} />,
//     name: "Diamond Roulette",
//   },
//   casino3: {
//     redirectUrl: "/casino/ezugi/NamasteRoulette",
//     img: <img src={NamasteRoulette} />,
//     name: "Namaste Roulette",
//   },
//   casino4: {
//     redirectUrl: "/casino/ezugi/AutoRoulette",
//     img: <img src={AutoRoulette} />,
//     name: "Auto Roulette",
//   },
//   casino5: {
//     redirectUrl: "/casino/ezugi/SpanishRoulette",
//     img: <img src={SpanishRoulette} />,
//     name: "Spanish Roulette",
//   },
//   casino6: {
//     redirectUrl: "/casino/ezugi/SpeedAutoRoulette",
//     img: <img src={SpeedAutoRoulette} />,
//     name: "Speed Auto Roulette",
//   },
//   casino7: {
//     redirectUrl: "/casino/ezugi/OracleRoulette2",
//     img: <img src={OracleRoulette2} />,
//     name: "Oracle Roulette 2",
//   },
//   casino8: {
//     redirectUrl: "/casino/ezugi/ItalianRoulette",
//     img: <img src={ItalianRoulette} />,
//     name: "Italian Roulette",
//   },
//   casino9: {
//     redirectUrl: "/casino/ezugi/RussianRoulette",
//     img: <img src={RussianRoulette} />,
//     name: "Russian Roulette",
//   },
//   casino10: {
//     redirectUrl: "/casino/ezugi/FiestaRoulette",
//     img: <img src={FiestaRoulette} />,
//     name: "Fiesta Roulette",
//   },
//   casino11: {
//     redirectUrl: "/casino/ezugi/PrestigeAutoRoulette",
//     img: <img src={PrestigeAutoRoulette} />,
//     name: "Prestige Auto Roulette",
//   },
//   casino12: {
//     redirectUrl: "/casino/ezugi/AndarBaharLive",
//     img: <img src={AndarBaharLive} />,
//     name: "Andar Bahar Live",
//   },
//   casino13: {
//     redirectUrl: "/casino/ezugi/OTTAndarBahar",
//     img: <img src={OTTAndarBahar} />,
//     name: "OTT Andar Bahar",
//   },
//   casino14: {
//     redirectUrl: "/casino/ezugi/UltimateAB",
//     img: <img src={ULtimateAB} />,
//     name: "Ultimate Andar Bahar",
//   },
//   casino15: {
//     redirectUrl: "/casino/ezugi/Baccaratpro",
//     img: <img src={Baccaratpro} />,
//     name: "Baccarat pro",
//   },
//   casino16: {
//     redirectUrl: "/casino/ezugi/Nocommissiobaccarat",
//     img: <img src={Nocommissiobaccarat} />,
//     name: "Nocommissio baccarat",
//   },
//   casino17: {
//     redirectUrl: "/casino/ezugi/Knockoutbaccarat",
//     img: <img src={Knockoutbaccarat} />,
//     name: "Knockout baccarat",
//   },
//   casino18: {
//     redirectUrl: "/casino/ezugi/Super6baccarat",
//     img: <img src={Super6baccarat} />,
//     name: "Super 6 baccarat",
//   },
//   casino19: {
//     redirectUrl: "/casino/ezugi/Super6baccarat",
//     img: <img src={Super6baccarat} />,
//     name: "Casino Marina Baccarat 1",
//   },
//   casino20: {
//     redirectUrl: "/casino/ezugi/Marinabaccarat",
//     img: <img src={Marinabaccarat} />,
//     name: "Super 6 Baccarat",
//   },
//   casino21: {
//     redirectUrl: "/casino/ezugi/Marina02baccarat",
//     img: <img src={Marina02baccarat} />,
//     name: "Marina 2 baccarat",
//   },
//   casino22: {
//     redirectUrl: "/casino/ezugi/SpeedCricketBaccarat",
//     img: <img src={Salsabaccarat} />,
//     name: "Speed Cricket Baccarat",
//   },
//   casino23: {
//     redirectUrl: "/casino/ezugi/Baccarat",
//     img: <img src={Baccarat} />,
//     name: "VIP Fortune Baccarat",
//   },
//   casino24: {
//     redirectUrl: "/casino/ezugi/Baccaratpro02",
//     img: <img src={Baccaratpro02} />,
//     name: "Speed Cricket Baccarat",
//   },
//   casino25: {
//     redirectUrl: "/casino/ezugi/SpeedBaccarat",
//     img: <img src={SpeedBaccarat} />,
//     name: "Speed Baccarat A",
//   },
//   casino26: {
//     redirectUrl: "/casino/ezugi/Marina03baccarat",
//     img: <img src={Marina03baccarat} />,
//     name: "Marina 04 Baccarat",
//   },
//   casino27: {
//     redirectUrl: "/casino/ezugi/Blackjack",
//     img: <img src={Blackjack} />,
//     name: "Blackjack",
//   },
//   casino28: {
//     redirectUrl: "/casino/ezugi/GoldBlackjack",
//     img: <img src={GoldBlackjack} />,
//     name: "Gold Blackjack 5",
//   },
//   casino29: {
//     redirectUrl: "/casino/ezugi/BlackjackPro",
//     img: <img src={BlackjackPro} />,
//     name: "Vip Surrender Blackjcak",
//   },
//   casino30: {
//     redirectUrl: "/casino/ezugi/VIPBlackjack",
//     img: <img src={VIPBlackjack} />,
//     name: "VIP Diamond Blackjack",
//   },
//   casino31: {
//     redirectUrl: "/casino/ezugi/BlackjackAutoSplit",
//     img: <img src={BlackjackAutoSplit} />,
//     name: "Italian Blackjack",
//   },
//   casino32: {
//     redirectUrl: "/casino/ezugi/TurkceBlackjack",
//     img: <img src={TurkceBlackjack} />,
//     name: "Turkish Blackjack 3",
//   },
//   casino33: {
//     redirectUrl: "/casino/ezugi/RussianBlackjack2",
//     img: <img src={LiveBlackjack} />,
//     name: "Russian Blackjack 2",
//   },
//   casino34: {
//     redirectUrl: "/casino/ezugi/UnlimitedBlackjack",
//     img: <img src={RumbaBlackjack} />,
//     name: "Unlimited Blackjack",
//   },
//   casino35: {
//     redirectUrl: "/casino/ezugi/DiamondBlackjack",
//     img: <img src={DiamondBlackjack} />,
//     name: "DiamondBlackjack",
//   },
//   casino36: {
//     redirectUrl: "/casino/ezugi/PlatinumBlackjack1",
//     img: <img src={Rumba02Blackjack} />,
//     name: "Platinum Blackjack 1",
//   },
//   casino37: {
//     redirectUrl: "/casino/ezugi/BlackjackParty",
//     img: <img src={VipBlackjack} />,
//     name: "Blackjack Party",
//   },
//   casino38: {
//     redirectUrl: "/casino/ezugi/Vip02Blackjack",
//     img: <img src={Vip02Blackjack} />,
//     name: "Speed VIP Blackjack D",
//   },
//   casino39: {
//     redirectUrl: "/casino/ezugi/TurkishBlackjack",
//     img: <img src={TurkishBlackjack} />,
//     name: "Turkish Blackjack 3",
//   },
//   casino40: {
//     redirectUrl: "/casino/ezugi/BlackjackLive",
//     img: <img src={BlackjackLive} />,
//     name: "Turkish Blackjack 2",
//   },
//   casino41: {
//     redirectUrl: "/casino/ezugi/PlatinumBlackjack",
//     img: <img src={PlatinumBlackjack} />,
//     name: "Turkish Umlimited Blackjack",
//   },
//   casino42: {
//     redirectUrl: "/casino/ezugi/TeenPatti08",
//     img: <img src={TeenPatti08} />,
//     name: "Dragon Tiger",
//   },
//   casino43: {
//     redirectUrl: "/casino/ezugi/TeenPatti01",
//     img: <img src={TeenPatti01} />,
//     name: "Teen Patti 3 Card",
//   },
//   casino44: {
//     redirectUrl: "/casino/ezugi/TeenPatti02",
//     img: <img src={TeenPatti02} />,
//     name: "Bet on Teen Patti",
//   },
//   casino45: {
//     redirectUrl: "/casino/ezugi/TeenPatti03",
//     img: <img src={TeenPatti03} />,
//     name: "One Day Teen Patti",
//   },
//   casino46: {
//     redirectUrl: "/casino/ezugi/TeenPatti04",
//     img: <img src={TeenPatti04} />,
//     name: "Casino Holdem",
//   },
//   casino47: {
//     redirectUrl: "/casino/ezugi/TeenPatti05",
//     img: <img src={TeenPatti05} />,
//     name: " SideBet City",
//   },
//   casino48: {
//     redirectUrl: "/casino/ezugi/TeenPatti06",
//     img: <img src={TeenPatti06} />,
//     name: " Texas Holem Bonus",
//   },
//   casino49: {
//     redirectUrl: "/casino/ezugi/TeenPatti07",
//     img: <img src={TeenPatti07} />,
//     name: "2 Hand Casino Holdem",
//   },
//   casino50: {
//     redirectUrl: "/casino/ezugi/SicBo",
//     img: <img src={SicBo} />,
//     name: "Sicbo",
//   },
//   casino51: {
//     redirectUrl: "/casino/ezugi/SuperSicBo",
//     img: <img src={SuperSicBo} />,
//     name: "Super Sicbo",
//   },
//   casino52: {
//     redirectUrl: "/casino/ezugi/UltimateSicBo",
//     img: <img src={UltimateSicBo} />,
//     name: "Ultimate Sicbo",
//   },
//   casino53: {
//     redirectUrl: "/casino/ezugi/thirtytwoCards",
//     img: <img src={thirtytwoCards} />,
//     name: "32 Card",
//   },
//   casino54: {
//     redirectUrl: "/casino/ezugi/sevenLucky",
//     img: <img src={sevenLucky} />,
//     name: "Lucky 7",
//   },
// };
