export const casinoTabs = {
  roulettetab: {
    PortomasoRoulette: {
      href: "/casino/vivo-portomaso-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/vivo/portomasoroulette.png", alt: "" },
      name: "Portomaso Roulette",
    },
    OracleBlazeRoulette: {
      href: "/casino/vivo-galaxy-roulette",
      code: "1000102",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/vivo/OracleBlazeRoulette.png", alt: "" },
      name: "Oracle Blaze Roulette",
    },
    OrientalRoulette: {
      href: "/casino/vivo-oriental-roulette",
      code: "1000011",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/vivo/orientelroulette.png", alt: "" },
      name: "Oriental Roulette",
    },
    Oracle360Roulette: {
      href: "/casino/vivo-oracle-360-roulette",
      code: "1000312",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/vivo/oracle360roulette.png", alt: "" },
      name: "Oracle 360 Roulette",
    },
    EuropeanAutoRoulette: {
      href: "/casino/vivo-european-auto-roulette",
      code: "1000120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/roulette/vivo/Europeanautoroulette.png",
        alt: "",
      },
      name: "European Auto Roulette",
    },
    FrenchRoulette: {
      href: "/casino/	vivo-french-roulette",
      code: "1000092",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/vivo/frenchrullete.png", alt: "" },
      name: "French Rulette",
    },
    LasVegasroulette1: {
      href: "/casino/vivo-las-vegas-roulette",
      code: 1000084,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/vivo/Lasvegasroulette.png", alt: "" },
      name: "Las Vegas Roulette",
    },
    VARoulette: {
      href: "/casino/vivo-va-roulette",
      code: "1000120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/roulette/vivo/VA-roulette.png",
        alt: "VA Roulette",
      },
    },

    namasteroulette: {
      href: "/casino/ezugi/namasteroulette",
      code: 221005,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casinio28.png", alt: "" },
    },
    UltimateRoulette: {
      href: "/casino/evolution/UltimateRoulette",
      code: "541000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/ultimate-roulette.png", alt: "" },
    },
    PortmasoRoulette2: {
      href: "/casino/ezg-portomaso-roulette-2",
      code: 611004,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/PortomasoRoulette2.png", alt: "" },
    },
    marinaroulette: {
      href: "/casino/ezugi/marinaroulette",
      code: 321000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino019.png", alt: "" },
    },
    OracleRoulette360: {
      href: "/casino/ezugi/OracleRoulette360",
      code: "611003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino030.png", alt: "" },
    },
    diamondroulette: {
      href: "/casino/ezugi/diamondroulette",
      code: "221003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino028.png", alt: "" },
    },
    speedroulette: {
      href: "/casino/ezugi/speedroulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino029.PNG", alt: "" },
    },
    oracleRoulette: {
      href: "/casino/ezugi/oracleRoulette",
      code: "611001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino010.png", alt: "" },
    },
    AutoRoulette1: {
      href: "/casino/ezugi/AutoRoulette1",
      code: "5001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino026.png", alt: "" },
    },
    SpanishRoulette: {
      href: "/casino/ezg-spanish-roulette",
      code: "411000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/roulette/SpanishRoulette.png",
        alt: "",
        className: "size_images",
      },
    },
    PrestigeAutoRoulette: {
      href: "/casino/ezugi/PrestigeAutoRoulette",
      code: "221004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino014.png", alt: "" },
    },
    FiestaRoulette: {
      href: "/casino/ezugi/FiestaRoulette",
      code: "431001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/Fiestaroulette.png", alt: "" },
    },

    RouletteSupernowa: {
      href: "/casino/sn-roulette",
      code: "431001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino078.png", alt: "" },
    },

    Dragonroulette: {
      href: "/casino/xpg-dragon-roulette",
      code: "36",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/roulette/casino023.png", alt: "" },
    },
  },
  baccarattab: {
    MacauBaccarat: {
      href: "/casino/vivo-macau-baccarat",
      code: "1000145",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/baccarat/vivo/maccaubaccarat.png", alt: "" },
      name: "MacauBaccarat",
    },
    VABaccarat4: {
      href: "/casino/vivo-va-bacarat-4",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/vivo/VA-baccrat.png", alt: "" },
      name: "VA Baccarat 4",
    },
    // HindiSpeedbaccarat: {
    //   href: "/casino/ezgevo-hindi-speed-baccarat",
    //   code: "120",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   name: "hindi speed baccarat",
    //   cover: { src: "./images/baccarat/evolution/casino03.png", alt: "" },
    // },
    GalaxyBaccarat1: {
      href: "/casino/vivo-galaxy-baccarat-1",
      code: "1000012",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/vivo/galaxybaccarat1.png", alt: "" },
      name: "Galaxy Baccarat 1",
    },

    Marinabaccarat: {
      href: "/casino/ezugi/Marinabaccarat",
      code: "130",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino031.png", alt: "" },
    },
    noCommisionBaccaratEzugi: {
      href: "/casino/ezugi/noCommisionBaccaratEzugi",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/Casinocomission.png", alt: "" },
    },
    BaccaratEzugi: {
      href: "/casino/ezg-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccaratB.PNG", alt: "" },
    },
    SpeedCricketBaccarat: {
      href: "/casino/ezg-speed-cricket-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/Speedcricketbaccarta.png", alt: "" },
    },
    VIPBaccarat: {
      href: "/casino/ezg-vip-fortune-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/VIPBaccarat.png", alt: "" },
    },
    Super6Baccarat: {
      href: "/casino/ezg-super-6-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino027.png", alt: "" },
    },
    KnockoutBaccarat: {
      href: "/casino/ezg-knockout-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/KnockoutBaccarat.png", alt: "" },
    },

    Baccarat: {
      href: "/casino/evolution/Baccarat",
      code: "BAC",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino080.PNG", alt: "" },
    },
    RNGBaccarat: {
      href: "/casino/sn-rng-baccarat",
      code: "BAC",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/baccarat/RNGBaccarat.png", alt: "" },
    },
  },
  AndarBahartab: {
    VivoAndarBahar: {
      href: "/casino/vivo-andar-bahar",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/vivo/andarbahar.png", alt: "" },
      name: "Andar Bahar",
    },

    AndarBaharLive: {
      href: "/casino/ezugi/AndarBaharLive",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino032.png", alt: "" },
    },
    OTTAndarBahar: {
      href: "/casino/ezugi/OTTAndarBahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino030.png", alt: "" },
    },
    UltimateAndarBahar: {
      href: "/casino/ezugi/UltimateAndarBahar",
      code: "228100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/ultimateandarbahar.png", alt: "" },
    },

    AndarBaharSupernowa: {
      href: "/casino/sn-andar-bahar",
      code: "AB",
      casino: "wco",
      provider: "SN",
      name: "supernova andar bahar",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino091.PNG", alt: "" },
    },
    GoaAndarBahar: {
      href: "/casino/sn-goas-andar-bahar",
      code: "AB2",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino090.PNG", alt: "" },
    },
    AndarBaharClassic: {
      href: "/casino/sn-classic-andar-bahar",
      code: "AB2",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/andarbahar/AndarBaharClassic.png", alt: "" },
    },
  },
  TeenPattitab: {
    VivoCasinoHoldem: {
      href: "/casino/vivo-casino-holdem",
      code: "227103",
      casino: "ezugi",
      name: "texas hold'em bonus poker",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/vivo/casinoholdem.png", alt: "" },
      name: "Casino Hold'em",
    },
    VivoTeenPatti: {
      href: "/casino/vivo-teen-patti",
      code: "227103",
      casino: "ezugi",
      name: "texas hold'em bonus poker",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/vivo/teenpatti.png", alt: "" },
      name: "Teen Patti	",
    },

    TexasHoldemBonusPoker: {
      href: "/casino/ezgevo-texas-holdem-bonus-poker",
      code: "227103",
      casino: "ezugi",
      name: "texas hold'em bonus poker",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/texasholdembonuspoker.png", alt: "" },
    },
    ThreeCardPoker: {
      href: "/casino/ezgevo-three-card-poker",
      code: "507000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/casino032.png", alt: "" },
    },

    TeenPatti03Card: {
      href: "/casino/ezg-teen-patti-3-card",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Teen Patti 3 Card",
      cover: { src: "./images/poker/teen-patti-3-card.png", alt: "" },
    },
    CasinoHoldem: {
      href: "/casino/ezg-casino-holdem",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Casinoholdem.png", alt: "" },
    },
    BetonTeenPattiEzugi: {
      href: "/casino/ezg-bet-on-teen-patti",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/casino032.png", alt: "" },
    },
    OneDayTeenPattiEzugi: {
      href: "/casino/ezg-one-day-teen-patti",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/one-day-teen-patti1.png", alt: "" },
    },
    OneDayTeenPattiClassicEzugi: {
      href: "/casino/ezugi/OneDayTeenPattiClassicEzugi",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/one-day-teen-patti.png", alt: "" },
    },

    RNGTeenPatti2020Poker: {
      href: "/casino/sn-rng-teen-patti-2020",
      code: "VPK",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      name: "Rng Teen patti",
      cover: { src: "./images/poker/RNGTeenPatti2020.png", alt: "" },
    },
    RNGTeenPattiPoker: {
      href: "/casino/sn-rng-teen-patti",
      code: "VPK",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      name: "Rng Teen patti 3",
      cover: { src: "./images/poker/RNGTeenPattiPoker.png", alt: "" },
    },
    RNG3playerTeenPattiPoker: {
      href: "/casino/sn-rng-3-player-teen-patti",
      code: "VPK",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/poker/RNG3playerTeenPattiPoker.png", alt: "" },
    },
    TeenPattiaceoffPoker: {
      href: "/casino/sn-teen-patti-face-off",
      code: "VPK",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      name: "Teen patti face off",
      cover: { src: "./images/poker/TeenPattiaceoffPoker.png", alt: "" },
    },
    TeenPatti2020Poker: {
      href: "/casino/sn-teen-patti-2020",
      code: "VPK",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      name: "Teen patti 2020",

      cover: { src: "./images/poker/TeenPatti2020Poker1.png", alt: "" },
    },
  },
  Blackjacktab: {
    LimitlessBlackjack: {
      href: "/casino/vivo-limitless-blackjack",
      code: "1000048",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Limitless Blackjack	",
      cover: { src: "./images/blackjack/vivo/limitlessblackjack.png", alt: "" },
    },
    BlackjackVip: {
      href: "/casino/platipus-blackjack-vip",
      code: "1",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Vip",
      cover: { src: "./images/blackjack/vivo/BlackjackVIP.png", alt: "" },
    },
    LasVegasBlackjack: {
      href: "/casino/vivo-las-vegas-blackjack",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Las Vegas Blackjack",
      cover: {
        src: "./images/blackjack/vivo/lasvegasblackjack.png",
        alt: "",
      },
    },
    BlackjackVivo: {
      href: "/casino/vivo-blackjack",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack ",
      cover: { src: "./images/blackjack/vivo/blackjackvivo.png", alt: "" },
    },
    blackjackVIP11: {
      href: "/casino/ezgevo-blackjack-vip-11",
      code: "51",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack vip 11",

      cover: { src: "./images/blackjack/blackjack-vip-11.png", alt: "" },
    },
    BlackjackSilverF: {
      href: "/casino/ezgevo-blackjack-silver-f",
      code: "422",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack silver f",

      cover: { src: "./images/blackjack/blackjack-silver-f.png", alt: "" },
    },
    blackjackA: {
      href: "/casino/ezgevo-blackjack-a",
      code: "411",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack A",
      cover: { src: "./images/blackjack/blackjack-a.png", alt: "" },
    },

    BlackjackGold4: {
      href: "/casino/ezg-gold-blackjack-4",
      code: "411",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Gold ",

      cover: { src: "./images/blackjack/gold-blackjack-4.png", alt: "" },
    },
    BlackjackPlatinum1: {
      href: "/casino/ezg-platinium-blackjack-1",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      name: "platinum blackjack",
      cover: { src: "./images/blackjack/platinium-blackjack-1.png", alt: "" },
    },
    BlackjackGold3: {
      href: "/casino/ezg-gold-blackjack-3",
      code: "414",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Gold 3",

      cover: { src: "./images/blackjack/gold-blackjack-3.png", alt: "" },
    },
    BlackjackGold1: {
      href: "/casino/ezg-gold-blackjack-1",
      code: "1000048",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Gold 1",

      cover: { src: "./images/blackjack/gold-blackjack-1.png", alt: "" },
    },
    BlackjackGold5: {
      href: "/casino/ezg-gold-blackjack-5",
      code: "1000032",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Gold 5",

      cover: { src: "./images/blackjack/gold-blackjack-5.png", alt: "" },
    },
    BlackJackGold6: {
      href: "/casino/ezg-gold-blackjack-6",
      code: "1000031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Gold 6",

      cover: { src: "./images/blackjack/gold-blackjack-6.png", alt: "" },
    },
    VIPSurrendeBlackjack: {
      href: "/casino/ezg-vip-surrender-blackjack",
      code: "422",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      name: "Vip surrender Blackjack",
      name: "vip surrender blackjack",
      cover: { src: "./images/blackjack/vip-diamond-blackjack.png", alt: "" },
    },
    BlackjackVIPDiamnd: {
      href: "/casino/ezg-vip-diamond-blackjack",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      name: "blackjack diamond vip",
      name: "Blackjack Diamond vip",

      cover: { src: "./images/blackjack/vip-diamond-blackjack.png", alt: "" },
    },

    PlaastinumBlackjacksss: {
      href: "/casino/ezugi/PlaastinumBlackjacksss",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino0224.png", alt: "" },
    },
  },
  thirtytwoCardtab: {
    thirtytwoCards: {
      href: "/casino/ezugi/thirtytwoCards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/card/casino057.png", alt: "" },
    },

    thirtytwoCardss: {
      href: "/casino/ezugi/thirtytwoCardss",
      code: "C32",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/card/casino0100.PNG", alt: "" },
    },
    RNG32cards: {
      href: "/casino/sn-rng-32-cards",
      code: "C32",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/card/RNG32cards.png", alt: "" },
    },
  },
  sevenLuckytab: {
    luckyseven: {
      href: "/casino/ezugi/luckyseven",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/lucky7/casino056.png", alt: "" },
    },

    Lucky7Supernowa: {
      href: "/casino/sn-lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/lucky7/lucky-7.png", alt: "" },
    },
    RNGLucky7: {
      href: "/casino/sn-rng-lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/lucky7/rng-lucky-7.png", alt: "" },
    },
  },
  sicbotab: {
    supersicbo: {
      href: "/casino/evolution/supersicbo",
      code: "1000110",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino060.png", alt: "" },
    },
    LightingDice: {
      href: "/casino/ezgevo-lightning-dice",
      code: "1000110",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino062.png", alt: "" },
    },

    ultimatesicbo: {
      href: "/casino/ezugi/ultimatesicbo",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino058.png", alt: "" },
    },
    sicboezugi: {
      href: "/casino/ezg-sic-bo",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/sicbo.png", alt: "" },
    },

    sicbo: {
      href: "/casino/xpg-sicbo",
      code: "38",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/dice/XPG-Sicbo.png", alt: "" },
    },
  },
  gameShows: {
    CrazyTime: {
      href: "/casino/ezgevo-crazy-time",
      code: "1000110",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino039.png", alt: "" },
    },

    deadorAliveSaloon: {
      href: "/casino/ezgevo-dead-or-alive-saloon",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/deadoralive.png", alt: "" },
    },
    Footballstudio: {
      href: "/casino/ezgevo-football-studio",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Football Studio",
      cover: { src: "./images/games/Footballstudio.png", alt: "" },
    },
  },
  BetOnNumberTab: {
    BetOnNumberHd: {
      href: "/casino/ezugi/BetOnNumberHd",
      code: "602000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino035.png", alt: "" },
    },
    CrazyTime: {
      href: "/casino/evolution/CrazyTime",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino039.png", alt: "" },
    },
    DreamCatcher: {
      href: "/casino/evolution/DreamCatcher",
      code: "1000077",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino0227.png", alt: "" },
    },
    MonopolyLive: {
      href: "/casino/evolution/MonopolyLive",
      code: "1000077",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino0229.png", alt: "" },
    },
    WheelOfFortune: {
      href: "/casino/xpg/WheelOfFortune",
      code: "224100",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/games/casino0228.png", alt: "" },
    },
  },
  indianGames: {
    AndarBaharVivo: {
      href: "/casino/vivo-andar-bahar",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/indian/vivoAndarBahar.jpg",
        alt: "vivo andar bahar",
      },
    },
    TeenPattiVivo: {
      href: "/casino/vivo-teen-patti",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/indian/vivoTeenpatti.jpg",
        alt: "vivo teen patti",
      },
    },
    IndianNamasteRoulette: {
      href: "/casino/ezugi/IndianNamasteRoulette",
      code: "221005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/01.jpg", alt: "" },
    },
    IndianTeenPatti: {
      href: "/casino/ezugi/IndianTeenPatti",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/02.jpg", alt: "" },
    },

    onedayteenpatti: {
      href: "/casino/ezugi/onedayteenpatti",
      code: "227102",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/03.jpg", alt: "" },
    },
    thirty2cards: {
      href: "/casino/ezugi/thirty2cards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/04.jpg", alt: "" },
    },
    beton: {
      href: "/casino/ezugi/beton",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/05.jpg", alt: "" },
    },
    oneday: {
      href: "/casino/ezugi/oneday",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/06.jpg", alt: "" },
    },
  },
  spribeGames: {
    mines: {
      href: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "",
      cover: { src: "./images/spribe/02.jpg", alt: "" },
    },
    goal: {
      href: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "",
      cover: { src: "./images/spribe/06.png", alt: "" },
    },

    dice: {
      href: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "",
      cover: { src: "./images/spribe/07.jpg", alt: "" },
    },
    aviator: {
      href: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "",
      cover: { src: "./images/spribe/03.png", alt: "" },
    },
    plinko: {
      href: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: "",
      cover: { src: "./images/spribe/08.jpg", alt: "" },
    },
    miniroulette: {
      href: "/casino/spribe/miniroulette",
      code: "mini-roulette",
      casino: "spribe",
      provider: "mini-roulette",
      homeUrl: "",
      cover: { src: "./images/spribe/04.png", alt: "" },
    },
    hilo: {
      href: "/casino/spribe/hilo",
      code: "hi-lo",
      casino: "spribe",
      provider: "hi-lo",
      homeUrl: "",
      cover: { src: "./images/spribe/05.png", alt: "" },
    },
  },
  popularGames: {
    lucky7: {
      href: "/casino/ezugi/lucky7",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/01.jpg", alt: "" },
    },
    // Monopoly: {
    //   href: "/casino/evolution/Monopoly",
    //   code: "228001",
    //   casino: "ezugi",
    //   provider: "EVO_LOBBY",
    //   homeUrl: "",
    //   cover: { src: "./images/popular/02.jpg", alt: "" },
    // },

    Dragontiger: {
      href: "/casino/ezugi/Dragontiger",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/03.jpg", alt: "" },
    },
    Speed: {
      href: "/casino/ezugi/Speed",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/04.jpg", alt: "" },
    },
    teenpatti3card: {
      href: "/casino/ezugi/teenpatti3card",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/05.jpg", alt: "" },
    },
    // Miniroulette2: {
    //   href: "/casino/ezugi/Miniroulette2",
    //   code: "228001",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/popular/06.jpg", alt: "" },
    // },
    thirty2cards: {
      href: "/casino/ezugi/thirty2cards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/04.jpg", alt: "" },
    },
  },
  Slots: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/twin-and-spin.jpg", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/gonzo-treasure-hunt.jpg", alt: "" },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/asgardianstones1.jpg", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/secrets.jpg", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/Grand-wheel.jpg", alt: "" },
    },
    Strike777: {
      href: "/casino/ezugi/Strike777",
      code: "1100031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Nights1000: {
      href: "/casino/ezugi/Nights1000",
      code: "1100003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashUltimate: {
      href: "/casino/ezugi/CashUltimate",
      code: "1100070",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashVolt: {
      href: "/casino/ezugi/CashVolt",
      code: "1100071",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DazzleMegaways: {
      href: "/casino/ezugi/DazzleMegaways",
      code: "1100083",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DivinieFortuneMegaways: {
      href: "/casino/ezugi/DivinieFortuneMegaways",
      code: "1100089",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Dynamiteriches: {
      href: "/casino/ezugi/Dynamiteriches",
      code: "1100073",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FortuneHouse: {
      href: "/casino/ezugi/FortuneHouse",
      code: "1100007",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShopMegaways: {
      href: "/casino/ezugi/FruitShopMegaways",
      code: "1100094",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenCryptex: {
      href: "/casino/ezugi/GoldenCryptex",
      code: "1100147",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenTsar: {
      href: "/casino/ezugi/GoldenTsar",
      code: "1100149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GorillaKingdom: {
      href: "/casino/ezugi/GorillaKingdom",
      code: "1100088",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MystryReels: {
      href: "/casino/ezugi/MystryReels",
      code: "1100061",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RageoftheSea: {
      href: "/casino/ezugi/RageoftheSea",
      code: "1100116",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelKingMegaways: {
      href: "/casino/ezugi/ReelKingMegaways",
      code: "1100052",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TurnFortune: {
      href: "/casino/ezugi/TurnFortune",
      code: "1100041",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TwinSpinMegaways: {
      href: "/casino/ezugi/TwinSpinMegaways",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WillysChills: {
      href: "/casino/ezugi/WillysChills",
      code: "1100128",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VikingsSlots: {
      href: "/casino/ezugi/VikingsSlots",
      code: "1100124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },

    ChineseTreasures: {
      href: "/casino/ezugi/ChineseTreasures",
      code: "1100006",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonLuck: {
      href: "/casino/ezugi/DragonLuck",
      code: "1100001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonFire: {
      href: "/casino/ezugi/DragonFire",
      code: "1100008",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonLuckDeluxe: {
      href: "/casino/ezugi/DragonLuckDeluxe",
      code: "1100011",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonLuckReel: {
      href: "/casino/ezugi/DragonLuckReel",
      code: "1100002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    EpicJourney: {
      href: "/casino/ezugi/EpicJourney",
      code: "1100012",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GodWealth: {
      href: "/casino/ezugi/GodWealth",
      code: "1100005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyFortune: {
      href: "/casino/ezugi/LuckyFortune",
      code: "1100010",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MagicGate: {
      href: "/casino/ezugi/MagicGate",
      code: "1100009",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenTemple: {
      href: "/casino/ezugi/GoldenTemple",
      code: "1100013",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PhoenixPower: {
      href: "/casino/ezugi/PhoenixPower",
      code: "1100014",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PiratesPlenty: {
      href: "/casino/ezugi/PiratesPlenty",
      code: "1100015",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenOffer: {
      href: "/casino/ezugi/GoldenOffer",
      code: "1100016",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RAsLegend: {
      href: "/casino/ezugi/RAsLegend",
      code: "1100017",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GonzoMegawaya: {
      href: "/casino/ezugi/GonzoMegawaya",
      code: "1100018                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RainbowJAcket: {
      href: "/casino/ezugi/RainbowJAcket",
      code: "1100019                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaPyramid: {
      href: "/casino/ezugi/MegaPyramid",
      code: "1100020                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Ozzy_Osbourne: {
      href: "/casino/ezugi/Ozzy_Osbourne",
      code: "1100024                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Jimi_Hendrix: {
      href: "/casino/ezugi/Jimi_Hendrix",
      code: "1100026                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Jimi_Hendrix: {
      href: "/casino/ezugi/Jimi_Hendrix",
      code: "1100026                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Jumanji: {
      href: "/casino/ezugi/Jumanji",
      code: "1100027                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MotorheadVideoSlot: {
      href: "/casino/ezugi/MotorheadVideoSlot",
      code: "1100028                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Starburst: {
      href: "/casino/ezugi/Starburst",
      code: "1100029                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WarofGod: {
      href: "/casino/ezugi/WarofGod",
      code: "1100030                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Christmas: {
      href: "/casino/ezugi/Christmas",
      code: "1100032                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ClusterPays: {
      href: "/casino/ezugi/ClusterPays",
      code: "1100033                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    AncientsBlessing: {
      href: "/casino/ezugi/AncientsBlessing",
      code: "1100034                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    AurumCodex: {
      href: "/casino/ezugi/AurumCodex",
      code: "1100035                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GunsNRoses: {
      href: "/casino/ezugi/GunsNRoses",
      code: "1100036                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    BorisandBoo: {
      href: "/casino/ezugi/BorisandBoo",
      code: "1100037                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildWest: {
      href: "/casino/ezugi/WildWest",
      code: "1100038                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SteamTower: {
      href: "/casino/ezugi/SteamTower",
      code: "1100043                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ZeusLightning: {
      href: "/casino/ezugi/ZeusLightning",
      code: "1100044                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WingsofRa: {
      href: "/casino/ezugi/WingsofRa",
      code: "1100045                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Trillionaire: {
      href: "/casino/ezugi/Trillionaire",
      code: "1100047                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TreasureMine: {
      href: "/casino/ezugi/TreasureMine",
      code: "1100048                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ThorVengeance: {
      href: "/casino/ezugi/ThorVengeance",
      code: "1100049                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RegalStreak: {
      href: "/casino/ezugi/RegalStreak",
      code: "1100050                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RegalBeasts: {
      href: "/casino/ezugi/RegalBeasts",
      code: "1100051                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelKeeper: {
      href: "/casino/ezugi/ReelKeeper",
      code: "1100053                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RainbowJackpotsPowerLines: {
      href: "/casino/ezugi/RainbowJackpotsPowerLines",
      code: "1100055                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PiggyRichesMegaways: {
      href: "/casino/ezugi/PiggyRichesMegaways",
      code: "1100059                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JingleBells: {
      href: "/casino/ezugi/JingleBells",
      code: "1100065                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JackinaPot: {
      href: "/casino/ezugi/JackinaPot",
      code: "1100066                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FiveStar: {
      href: "/casino/ezugi/FiveStar",
      code: "1100068                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    BountyRaid: {
      href: "/casino/ezugi/BountyRaid",
      code: "1100069                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MysteryReels2: {
      href: "/casino/ezugi/MysteryReels2",
      code: "1100061                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DiamondBlitz: {
      href: "/casino/ezugi/DiamondBlitz",
      code: "1100072                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonFireMegaways: {
      href: "/casino/ezugi/DragonFireMegaways",
      code: "1100074                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Rome: {
      href: "/casino/ezugi/Rome",
      code: "1100075                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelRush: {
      href: "/casino/ezugi/ReelRush",
      code: "1100076                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    BloodSuckers: {
      href: "/casino/ezugi/BloodSuckers",
      code: "1100077                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Pyramid: {
      href: "/casino/ezugi/Pyramid",
      code: "1100078                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ButterflyStaxx: {
      href: "/casino/ezugi/ButterflyStaxx",
      code: "1100079                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PiggyRiches: {
      href: "/casino/ezugi/PiggyRiches",
      code: "1100080                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DarkKing: {
      href: "/casino/ezugi/DarkKing",
      code: "1100081                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JingleSpin: {
      href: "/casino/ezugi/JingleSpin",
      code: "1100082                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Dazzle: {
      href: "/casino/ezugi/Dazzle",
      code: "1100083                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JackHammer: {
      href: "/casino/ezugi/JackHammer",
      code: "1100084                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DeadorAlive: {
      href: "/casino/ezugi/DeadorAlive",
      code: "1100085                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JackandtheBeanstalk: {
      href: "/casino/ezugi/JackandtheBeanstalk",
      code: "1100086                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DeadorAlive2: {
      href: "/casino/ezugi/DeadorAlive2",
      code: "1100087                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DivineFortuneMegaways: {
      href: "/casino/ezugi/DivineFortuneMegaways",
      code: "1100089                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GordonRamsayHellsKitchen: {
      href: "/casino/ezugi/GordonRamsayHellsKitchen",
      code: "1100090                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Elements: {
      href: "/casino/ezugi/Elements",
      code: "1100091                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GonzosQuest: {
      href: "/casino/ezugi/GonzosQuest",
      code: "1100092                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FinnandtheSwirlySpin: {
      href: "/casino/ezugi/FinnandtheSwirlySpin",
      code: "1100093                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShop: {
      href: "/casino/ezugi/FruitShop",
      code: "1100095                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    BloodSuckers2: {
      href: "/casino/ezugi/BloodSuckers2",
      code: "1100097                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ButterflyStaxx2: {
      href: "/casino/ezugi/ButterflyStaxx2",
      code: "1100098                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DazzleMeMegaways: {
      href: "/casino/ezugi/DazzleMeMegaways",
      code: "1100099                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DeadorAlive2FeatureBuy: {
      href: "/casino/ezugi/DeadorAlive2FeatureBuy",
      code: "1100100                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DoubleStacks: {
      href: "/casino/ezugi/DoubleStacks",
      code: "1100101                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FairytaleLegends: {
      href: "/casino/ezugi/FairytaleLegends",
      code: "1100102                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Flowers: {
      href: "/casino/ezugi/Flowers",
      code: "1100103                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShopChristmasEdition: {
      href: "/casino/ezugi/FruitShopChristmasEdition",
      code: "1100104                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Hotline: {
      href: "/casino/ezugi/Hotline",
      code: "1100107                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Hotline2: {
      href: "/casino/ezugi/Hotline2",
      code: "1100108                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TheInvisibleMan: {
      href: "/casino/ezugi/TheInvisibleMan",
      code: "1100109                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    IrishPotLuck: {
      href: "/casino/ezugi/IrishPotLuck",
      code: "1100110                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JackHammer2: {
      href: "/casino/ezugi/JackHammer2",
      code: "1100111                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitSpin: {
      href: "/casino/ezugi/FruitSpin",
      code: "1100112                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JungleSpirit: {
      href: "/casino/ezugi/JungleSpirit",
      code: "1100113                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    KoiPrincess: {
      href: "/casino/ezugi/KoiPrincess",
      code: "1100114                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DriveMultiplierMayhem: {
      href: "/casino/ezugi/DriveMultiplierMayhem",
      code: "1100115                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RageoftheSeas: {
      href: "/casino/ezugi/RageoftheSeas",
      code: "1100116                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RichesofMidgard: {
      href: "/casino/ezugi/RichesofMidgard",
      code: "1100117                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ScruffyDuck: {
      href: "/casino/ezugi/ScruffyDuck",
      code: "1100118                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SecretoftheStones: {
      href: "/casino/ezugi/SecretoftheStones",
      code: "1100119                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SecretsofChristmas: {
      href: "/casino/ezugi/SecretsofChristmas",
      code: "1100120                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SerengetiKings: {
      href: "/casino/ezugi/SerengetiKings",
      code: "1100121                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SpaceWars: {
      href: "/casino/ezugi/SpaceWars",
      code: "1100122                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Victorious: {
      href: "/casino/ezugi/Victorious",
      code: "1100123                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Warlords: {
      href: "/casino/ezugi/Warlords",
      code: "1100125                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildWater: {
      href: "/casino/ezugi/WildWater",
      code: "1100126                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildWorlds: {
      href: "/casino/ezugi/WildWorlds",
      code: "1100127                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Squad4: {
      href: "/casino/ezugi/Squad4",
      code: "1100129                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Families5: {
      href: "/casino/ezugi/Families5",
      code: "1100130                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    AgentRoyale: {
      href: "/casino/ezugi/AgentRoyale",
      code: "1100131                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ArcadeBomb: {
      href: "/casino/ezugi/ArcadeBomb",
      code: "1100132                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Atlantis: {
      href: "/casino/ezugi/Atlantis",
      code: "1100133                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    AztecSpins: {
      href: "/casino/ezugi/AztecSpins",
      code: "1100134                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CinderellaBall: {
      href: "/casino/ezugi/CinderellaBall",
      code: "1100135                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CirquedelaFortune: {
      href: "/casino/ezugi/CirquedelaFortune",
      code: "1100136                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ClashoftheBeasts: {
      href: "/casino/ezugi/ClashoftheBeasts",
      code: "1100137                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CrazyGenie: {
      href: "/casino/ezugi/CrazyGenie",
      code: "1100138                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CrystalMirror: {
      href: "/casino/ezugi/CrystalMirror",
      code: "1100139                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DaVinciMystery: {
      href: "/casino/ezugi/DaVinciMystery",
      code: "1100140                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DevilNumber: {
      href: "/casino/ezugi/DevilNumber",
      code: "1100141                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DiceDice: {
      href: "/casino/ezugi/DiceDice",
      code: "1100142                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FiveStarsPowerReels: {
      href: "/casino/ezugi/FiveStarsPowerReels",
      code: "1100144                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GemsGoneWild: {
      href: "/casino/ezugi/GemsGoneWild",
      code: "1100145                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GemsGoneWildPowerReels: {
      href: "/casino/ezugi/GemsGoneWildPowerReels",
      code: "1100146                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenLeprechaunMegaways: {
      href: "/casino/ezugi/GoldenLeprechaunMegaways",
      code: "1100148                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    HoardofPoseidon: {
      href: "/casino/ezugi/HoardofPoseidon",
      code: "1100151                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JewelScarabs: {
      href: "/casino/ezugi/JewelScarabs",
      code: "1100152                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JingleBellsPowerReels: {
      href: "/casino/ezugi/JingleBellsPowerReels",
      code: "1100153                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LegendofAthena: {
      href: "/casino/ezugi/LegendofAthena",
      code: "1100154                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyHalloween: {
      href: "/casino/ezugi/LuckyHalloween",
      code: "1100155                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyLittleDevil: {
      href: "/casino/ezugi/LuckyLittleDevil",
      code: "1100156                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyValentine: {
      href: "/casino/ezugi/LuckyValentine",
      code: "1100157                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyWizard: {
      href: "/casino/ezugi/LuckyWizard",
      code: "1100158                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Masquerade: {
      href: "/casino/ezugi/Masquerade",
      code: "1100159                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MayanGods: {
      href: "/casino/ezugi/MayanGods",
      code: "1100160                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaDragon: {
      href: "/casino/ezugi/MegaDragon",
      code: "1100161                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaJade: {
      href: "/casino/ezugi/MegaJade",
      code: "1100162                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaRise: {
      href: "/casino/ezugi/MegaRise",
      code: "1100163                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MultiplierRiches: {
      href: "/casino/ezugi/MultiplierRiches",
      code: "1100164                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MysteryReelsMegaways: {
      href: "/casino/ezugi/MysteryReelsMegaways",
      code: "1100165                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    NinjaWays: {
      href: "/casino/ezugi/NinjaWays",
      code: "1100166                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PathofDestiny: {
      href: "/casino/ezugi/PathofDestiny",
      code: "1100167                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PersianFortune: {
      href: "/casino/ezugi/PersianFortune",
      code: "1100168                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelHeist: {
      href: "/casino/ezugi/ReelHeist",
      code: "1100169                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RobinHoodWildForest: {
      href: "/casino/ezugi/RobinHoodWildForest",
      code: "1100170                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SnowWild: {
      href: "/casino/ezugi/SnowWild",
      code: "1100171                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SylvanSpirits: {
      href: "/casino/ezugi/SylvanSpirits",
      code: "1100172                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TheGreatestTrainRobbery: {
      href: "/casino/ezugi/TheGreatestTrainRobbery",
      code: "1100173                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TheWildHatter: {
      href: "/casino/ezugi/TheWildHatter",
      code: "1100174                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ThorLightning: {
      href: "/casino/ezugi/ThorLightning",
      code: "1100175                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TikiFruits: {
      href: "/casino/ezugi/TikiFruits",
      code: "1100176                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TotemLightning: {
      href: "/casino/ezugi/TotemLightning",
      code: "1100177                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VaultCracker: {
      href: "/casino/ezugi/VaultCracker",
      code: "1100178                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VaultofAnubis: {
      href: "/casino/ezugi/VaultofAnubis",
      code: "1100179                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WellofWishes: {
      href: "/casino/ezugi/WellofWishes",
      code: "1100180                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildCatsMultiline: {
      href: "/casino/ezugi/WildCatsMultiline",
      code: "1100181                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildOClock: {
      href: "/casino/ezugi/WildOClock",
      code: "1100182                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildChest: {
      href: "/casino/ezugi/WildChest",
      code: "1100183                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WinEscalator: {
      href: "/casino/ezugi/WinEscalator",
      code: "1100184                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    YucatanMystery: {
      href: "/casino/ezugi/YucatanMystery",
      code: "1100185                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
  },
  slotsgames: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "wco",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/01.jpg", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/02.jpg", alt: "" },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/03.jpg", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/04.jpg", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/05.jpg", alt: "" },
    },
  },
  Proivdergames: {
    Evolution: {
      href: "/casino/evolution",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/01.png", alt: "" },
    },
    ezugi: {
      href: "/casino/ezugi",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/02.png", alt: "" },
    },

    supernova: {
      href: "/casino/supernowa",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/03.png", alt: "" },
    },
    xpg: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/04.png", alt: "" },
    },
  },
  PaymentMethod: {
    netbanking: {
      href: "/casino/Evolution",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/netbanking.svg", alt: "" },
    },
    upi: {
      href: "/casino/ezugi",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/upi.svg", alt: "" },
    },

    visa: {
      href: "/casino/supernova",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/visa.svg", alt: "" },
    },
    mastercard: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/mastercard_white.svg", alt: "" },
    },
    paytm: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/paytm.svg", alt: "" },
    },
    Phonepe: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/phonepe.svg", alt: "" },
    },
    gpay: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/gpay.svg", alt: "" },
    },
    airtel: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/airtel.svg", alt: "" },
    },
  },
};
