import { casinoTabs } from "./casinoTabs.data";

export const EzugiGames = {
  ...casinoTabs.roulettetab,
  ...casinoTabs.AndarBahartab,
  ...casinoTabs.TeenPattitab,
  ...casinoTabs.Blackjacktab,
  ...casinoTabs.thirtytwoCardtab,
  ...casinoTabs.sevenLuckytab,
  ...casinoTabs.sicbotab,
  ...casinoTabs.BetOnNumberTab,
  ...casinoTabs.baccarattab,
  ...casinoTabs.indianGames,
  ...casinoTabs.popularGames,
  ...casinoTabs.slotsgames,
  ...casinoTabs.Slots,
};
