import { casinoTabs } from "./casinoTabs.data";

export const EvolutionGames = {
  ...casinoTabs.roulettetab,
  ...casinoTabs.baccarattab,
  ...casinoTabs.Blackjacktab,
  ...casinoTabs.sicbotab,
  ...casinoTabs.BetOnNumberTab,
  ...casinoTabs.AndarBahartab,
  ...casinoTabs.indianGames,
  ...casinoTabs.popularGames,
  ...casinoTabs.slotsgames,
};
