import React, { useEffect } from "react";
import TestPageContent from "../../../components/testPageContent";
import Header from "../../../../containers/Header";
import { getBanners } from "../../../../redux/app/actions";
import { useDispatch } from "react-redux";
import MainSlider from "../../../../containers/TestSlider";
const TestLandingPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBanners());
  }, []);
  return (
    <>
      <Header />

      <MainSlider />

      <TestPageContent />
    </>
  );
};
export default TestLandingPage;
