import React, { Component } from "react";
import Slider from "react-slick";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Banner5 from "../assets/images/banner-img5.jpg";

const MainSlider = ({ banners }) => {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Slider>
        {banners &&
          banners?.map((item, index) => {
            const btn = item?.ctabtns;
            btn.sort((a, b) => a.order - b.order);
            return (
              <div
                className="position-relative d-flex align-items-center"
                key={index}
              >
                <img
                  src={item?.link}
                  style={{ aspectRatio: "16/9", objectFit: "cover" }}
                />
                {btn.length > 0 &&
                  btn.map((b, i) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          width: "500px",
                          position: "relative",
                        }}
                      >
                        <Button
                          onClick={() => {
                            window.location.href = b.actionLink;
                          }}
                          className="start-btn"
                        >
                          {b.label}
                        </Button>
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default MainSlider;
